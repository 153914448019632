<div id="region-management">
    <adm-header [title]="'NAVIGATION_REGIONMANAGEMENT' | translate">
        <ng-template admHeaderActionButtons>
            <button class="btn btn-fa" [ngbTooltip]="'ADMIN_EXPORT_EXCEL_BTN' | translate"
                    (click)="grid.exportExcel()">
                <fa-icon [icon]="['fal', 'file-excel']" size="2x"></fa-icon>
            </button>
        </ng-template>
    </adm-header>
    <adm-data-grid #grid (selectionChange)="selectRow($event)"
                   [selectable]="true" [data]="dataGridService | async"
                   (excelExport)="onExcelExport($event)" [dataGridState]="dataGridState">
        <adm-data-grid-column field="name" title="{{'LANGUAGES_NAME' | translate}}">
            <ng-template admDataGridCell let-dataItem>
                <span class="flag-icon flag-icon-{{ dataItem.name | lowercase }} me-2"></span>
                {{ dataItem.name }}
            </ng-template>
        </adm-data-grid-column>
        <adm-data-grid-column field="displayName" [filterable]="false" [sortable]="false" [editable]="false"
                              title="{{ 'SHARED_REGION' | translate }}">
            <ng-template admDataGridCell let-dataItem>
                {{ dataItem.displayName }}
            </ng-template>
        </adm-data-grid-column>
        <adm-data-grid-column field="catalogUrl"
                              title="{{ 'ADMIN_REGIONMANAGEMENT_CATALOGURL' | translate }}">
            <ng-template admDataGridCell let-dataItem>
                {{ dataItem.catalogUrl }}
            </ng-template>
        </adm-data-grid-column>
        <adm-data-grid-column field="globalLocationNumber"
                              title="{{ 'ADMIN_REGIONMANAGEMENT_GLOBALLOCATIONNUMBER' | translate }}">
            <ng-template admDataGridCell let-dataItem>
                {{ dataItem.globalLocationNumber }}
            </ng-template>
        </adm-data-grid-column>
        <adm-data-grid-column field="isPriceUsed" [filterable]="false"
                              title="{{ 'ADMIN_REGIONMANAGEMENT_USEPRICEVALIDATION' | translate }}">
            <ng-template admDataGridCell let-dataItem>
                {{ dataItem.isPriceUsed | yesNo | translate }}
            </ng-template>
        </adm-data-grid-column>
        <adm-data-grid-column field="isPriceVisible" [filterable]="false"
                              title="{{ 'ADMIN_REGIONMANAGEMENT_SHOWREGIONPRICES' | translate }}">
            <ng-template admDataGridCell let-dataItem>
                {{ dataItem.isPriceVisible | yesNo | translate }}
            </ng-template>
        </adm-data-grid-column>
        <adm-data-grid-column field="isElbridgeEnabled" [filterable]="false"
                              title="{{ 'ADMIN_REGIONMANAGEMENT_ISELBRIDGEENABLED' | translate }}">
            <ng-template admDataGridCell let-dataItem>
                {{ dataItem.isElbridgeEnabled | yesNo | translate }}
            </ng-template>
        </adm-data-grid-column>
        <adm-data-grid-excel fileName="regions.xlsx"></adm-data-grid-excel>
    </adm-data-grid>
</div>

<ng-template #form>
    <form class="modal" [formGroup]="formGroup">
        <div class="modal-header">
            <h3 class="mb-0">{{ 'NAVIGATION_REGIONMANAGEMENT' | translate }}</h3>
            <button class="btn-close" (click)="close()"></button>
        </div>
        <div class="modal-body">
            <div class="mb-3">
                <label for="name">
                    {{ 'LANGUAGES_NAME' | translate }}
                </label>
                <input class="form-control" type="text" id="name" formControlName="name" readonly />
            </div>
            <div class="mb-3">
                <label for="displayName">
                    {{ 'LANGUAGES_DISPLAYNAME' | translate }}
                </label>
                <input class="form-control" type="text" id="displayName" formControlName="displayName"
                       readonly />
            </div>
            <div class="mb-3">
                <label for="catalogUrl">
                    {{ 'ADMIN_REGIONMANAGEMENT_CATALOGURL' | translate }}
                    <shd-info-button
                        text="{{ 'ADMIN_REGIONMANAGEMENT_CATALOGURL_AVAILABLEPLACEHOLDERS' | translate }}"
                        placement="top"></shd-info-button>
                </label>
                <input class="form-control" type="text" id="catalogUrl" formControlName="catalogUrl" />
            </div>
            <div class="mb-3">
                <label for="globalLocationNumber">
                    {{ 'ADMIN_REGIONMANAGEMENT_GLOBALLOCATIONNUMBER' | translate }}
                </label>
                <input
                    class="form-control"
                    type="number"
                    id="globalLocationNumber"
                    formControlName="globalLocationNumber" />
            </div>
            <div class="mb-3">
                <div class="form-check">
                    <input
                        type="checkbox"
                        formControlName="isPriceUsed"
                        id="isPriceUsed"
                        class="form-check-input" />
                    <label class="form-check-label text-muted"
                           for="isPriceUsed">
                        {{ 'ADMIN_REGIONMANAGEMENT_USEPRICEVALIDATION' | translate }}
                    </label>
                </div>
            </div>
            <div class="mb-3">
                <div class="form-check">
                    <input
                        type="checkbox"
                        formControlName="isPriceVisible"
                        id="isPriceVisible"
                        class="form-check-input" />
                    <label class="form-check-label text-muted"
                           for="isPriceVisible">
                        {{ 'ADMIN_REGIONMANAGEMENT_SHOWREGIONPRICES' | translate }}
                    </label>
                </div>
            </div>
            <div class="mb-3">
                <div class="form-check">
                    <input
                        type="checkbox"
                        formControlName="isElbridgeEnabled"
                        id="isElbridgeEnabled"
                        class="form-check-input" />
                    <label class="form-check-label text-muted"
                           for="isElbridgeEnabled">
                        {{ 'ADMIN_REGIONMANAGEMENT_ISELBRIDGEENABLED' | translate }}
                    </label>
                </div>
            </div>
            <div class="mb-3">
                <h4>{{ 'ADMIN_MODULESTATUS' | translate }}</h4>
                <ul class="list-unstyled">
                    @for (control of formModules.controls; track control; let i = $index) {
                        <li formGroupName="modules">
                            <div class="mb-3">
                                <div class="form-check" [formGroupName]="i">
                                    <input
                                        type="checkbox"
                                        formControlName="isActive"
                                        [attr.id]="'module-' + i"
                                        class="form-check-input" />
                                    <label
                                        class="form-check-label text-muted"
                                        [attr.for]="'module-' + i">
                                        {{ control.value.name }}
                                    </label>
                                </div>
                            </div>
                        </li>
                    }
                </ul>
            </div>
        </div>
        <div class="modal-footer">
            <div class="d-flex justify-content-between w-100">
                <button class="btn btn-secondary" type="button" (click)="cancelHandler()">
                    {{ 'BTN_ABORT' | translate }}
                </button>
                <button class="btn btn-success" type="button" (click)="saveHandler()">
                    {{ 'BTN_SAVE' | translate }}
                </button>
            </div>
        </div>
    </form>
</ng-template>
