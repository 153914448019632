import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Logger } from '@obo-main/utils/logger/logger.service';
import { User } from '@obo-account/models/account.models';
import { tap } from 'rxjs/operators';

@Injectable()
export class RegisterService {
    constructor(@Inject('APIPREFIX') private apiPrefix: string, private http: HttpClient, private logger: Logger) {}

    /**
     * registers a new account on api
     * @param user
     */
    public register(user: User) {
        this.logger.debug(`Attempting to register user ${user.email}`);
        return this.http.post<{ id: number }>(`${this.apiPrefix}Accounts/Register`, user).pipe(
            tap((res) => {
                this.logger.debug(`Received response from API:`, res);
            })
        );
    }
}
