<div [id]="fabId" class="fab-container" [class.isSecond]="isSecondBtn" shdClickOutside
     (clickOutside)="isOpen ? toggle(): null">
    <div class="fab-menu" [class.active]="(state | async).display">
        <a
            class="fab-toggle {{ disabled ? 'disabled' : '' }}"
            [class.hasLabel]="label"
            [style.background-color]="buttonColor"
            (click)="toggle()">
            <div [class.hasNoChildren]="hasNoChildren">
                <fa-icon [icon]="[faPrefix, faIcon]" size="lg"></fa-icon>
            </div>
            @if (label) {
                <span class="ms-2">{{ label }}</span>
            }
        </a>
        <div class="fab-menu-items">
            <ng-content></ng-content>
        </div>
    </div>
</div>
