<div class="project-card card-grow card-shadow" uiSref="project" [uiParams]="{ projectId: project.id }">
    <div class="project-card-header">
        <div class="project-caption">
            <div class="project-name mb-1">{{ project.name }}</div>
            @if (project.customerName) {
            <div class="mb-1 project-customer-name">
                {{ project.customerName }}
            </div>
            } @if (project.buildingCount) {
            <div class="mb-2 project-building-count">{{ project.buildingCount }} {{ 'SHARED_BUILDINGS' | translate }}</div>
            }
        </div>
        <div class="project-context-menu">
            <shd-contextmenu [overflowHidden]="true" size="2x">
                <button class="btn btn-contextmenu" (click)="duplicateProject()">
                    <fa-icon class="me-2" [icon]="['fal', 'copy']"></fa-icon>
                    {{ 'BTN_COPY' | translate }}
                </button>
                <button class="btn btn-contextmenu" (click)="editProject()">
                    <fa-icon class="me-2" [icon]="['fal', 'pencil']"></fa-icon>
                    {{ 'BTN_EDIT' | translate }}
                </button>
                <button class="btn btn-contextmenu" (click)="confirmDeleteModal.show()">
                    <fa-icon class="me-2" [icon]="['fal', 'trash-alt']"></fa-icon>
                    {{ 'BTN_DELETE' | translate }}
                </button>
            </shd-contextmenu>
        </div>
    </div>
    <div class="project-card-footer">
        <div>{{ 'DASHBOARD_USAGEDATE' | translate }} {{ project.usageDate | humanlyDate : language : true | async }}</div>
        <div class="d-flex">
            @for (module of plannedModules; track module) {
            <shd-module-icon class="module-icon" [moduleName]="module" [color]="'black'"></shd-module-icon>
            }
        </div>
    </div>
</div>

<shd-confirm-modal
    #confirmDeleteModal
    [title]="'BTN_DELETE' | translate"
    [question]="'PROJECT_LABEL_DELETECONFIRM' | translate"
    (confirmed)="deleteProject()"></shd-confirm-modal>
