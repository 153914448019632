import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Logger } from '@obo-main/utils/logger/logger.service';
import { Observable } from 'rxjs';

@Injectable()
export class ConfirmAccountService {
    constructor(@Inject('APIPREFIX') private apiPrefix: string, private http: HttpClient, private logger: Logger) {}

    /**
     * tries to activate an user account
     * @param token
     * @param userId
     */
    public activateUserAccount(token: string, userId: string): Observable<any> {
        this.logger.debug(`Trying to activate User ${userId} Account ${token}`);
        return this.http.put(`${this.apiPrefix}Accounts/Confirm`, {
            token,
            id: userId
        });
    }
}
