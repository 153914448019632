import { Injectable } from '@angular/core';
import { Address } from '@obo-dashboard/models/projectManagement.models';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AddressService {
    public addressSubject: BehaviorSubject<Address>;

    public initAddressSubject(address: Address) {
        if (!this.addressSubject) this.addressSubject = new BehaviorSubject(address);
        else this.addressSubject.next(address);
    }

    public static convertPlaceResultToAddress(placeResult: google.maps.places.PlaceResult): Address {
        let address;
        address = new Address();
        address.latitude = placeResult.geometry?.location.lat();
        address.longitude = placeResult.geometry?.location.lng();

        placeResult.address_components.forEach((c) => {
            if (c.types.some((t) => t === 'locality')) address.city = c.long_name;
            else if (c.types.some((t) => t === 'postal_code')) address.postalCode = c.long_name;
            else if (c.types.some((t) => t === 'route')) address.street = c.long_name;
            else if (c.types.some((t) => t === 'street_number')) address.houseNumber = c.long_name;
        });

        return address;
    }
}
