import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Culture, ExcelExportResponse } from '@obo-admin/admin.models';
import { OboMultiSelectFilter } from '@obo-common/filter/models/multiSelect';
import { OboSingleSelectFilter, OboSingleSelectFilterOption } from '@obo-common/filter/models/singleSelect';
import { AlertService } from '@obo-main/services/common/alert/alert.service';
import { SpinnerService } from '@obo-main/services/common/spinner/spinner.service';
import { finalize } from 'rxjs/operators';
import { TranslationManagementService } from '../translationManagement.service';
import { TranslationExportOptions } from './translation.model';
import { FileSavingService } from '@obo-common/shared/services/fileSaving.service';
import { ActiveModal } from '@obo-main/services/modal/activeModal.model';

@Component({
    selector: 'app-export-translations',
    templateUrl: './translationExportModal.component.html',
    styleUrls: ['./translationExportModal.component.scss']
})
export class TranslationExportModalComponent implements OnInit {
    public formGroup: UntypedFormGroup;

    constructor(
        @Inject('allLanguages') private allLanguages: Culture[],
        @Inject('selectedLanguages')
        private selectedLanguages: OboMultiSelectFilter<any>,
        @Inject('selectedModules')
        private selectedModules: OboMultiSelectFilter<any>,
        private translationManagementService: TranslationManagementService,
        private translateService: TranslateService,
        private spinnerService: SpinnerService,
        private alertService: AlertService,
        private fileSaver: FileSavingService,
        @Inject(ActiveModal) public activeModal: ActiveModal
    ) {}

    public ngOnInit(): void {
        let sourceLanguageFilterOptions: OboSingleSelectFilterOption<any>[] = [];
        this.allLanguages.forEach((c) => {
            sourceLanguageFilterOptions.push(new OboSingleSelectFilterOption(`${c.displayName} (${c.name})`, c.name, () => true));
        });

        this.selectedLanguages.label = this.translateService.instant('ADMIN_TRANSLATION_TARGETLANGUAGES');

        this.formGroup = new UntypedFormGroup({
            sourceLanguage: new OboSingleSelectFilter(
                this.translateService.instant('ADMIN_TRANSLATION_SOURCELANGUAGE'),
                false,
                sourceLanguageFilterOptions,
                true
            ),
            languages: this.selectedLanguages,
            modules: this.selectedModules,
            isAcrossExport: new UntypedFormControl(false)
        });

        this.formGroup.controls.sourceLanguage.setValidators(Validators.required);
        this.formGroup.controls.languages.setValidators(Validators.required);
        this.formGroup.controls.modules.setValidators(Validators.required);
    }

    public exportTranslations() {
        const selectedModuleIds = (this.formGroup.controls.modules as OboMultiSelectFilter<any>).controls
            .filter((o) => o.isActive)
            .map((o) => o.filterValue);

        const selectedSourceLanguage = (this.formGroup.controls.sourceLanguage as OboSingleSelectFilter<any>).value.filterValue;
        const sourceLanguageIdToExport = this.allLanguages.find((l) => l.name === selectedSourceLanguage)?.id;

        const selectedLanguages = (this.formGroup.controls.languages as OboMultiSelectFilter<any>).controls
            .filter((o) => o.isActive)
            .map((o) => o.filterValue);
        const languageIdsToExport = this.allLanguages
            .filter((l) => selectedLanguages.includes(l.name))
            .map((languageToExport) => languageToExport.id);

        const emptyTargets = this.formGroup.controls.isAcrossExport.value;

        this.spinnerService.startSpinner();
        this.translationManagementService
            .exportTranslationFile(
                sourceLanguageIdToExport!,
                languageIdsToExport!,
                selectedModuleIds,
                emptyTargets ? TranslationExportOptions.EmptyTargets : TranslationExportOptions.None
            )
            .pipe(finalize(() => this.spinnerService.stopSpinner()))
            .subscribe({
                next: (res: ExcelExportResponse) => {
                    this.fileSaver.saveFile(res.blob, res.name);
                    this.activeModal.close();
                },
                error: () => this.alertService.danger('Error')
            });
    }
}
