export namespace Constants {
    export enum ResponsiveBreakpoint {
        xs = 'xs',
        sm = 'sm',
        md = 'md',
        lg = 'lg',
        xl = 'xl',
        xxl = 'xxl',
        xxxl = 'xxxl'
    }

    export const clientId = 'FCD4F7FF-11C0-467D-97A5-DE40459060EE';
    export const storageTokens = {
        ufs_configuration: 'OBO_CONSTRUCT_UFS_SAVED_CONFIGURATION',
        cablefilling_configuration: 'OBO_CONSTRUCT_CF_SAVED_CONFIGURATION',
        access_token: 'OBO_CONSTRUCT_ACCESSTOKEN',
        refresh_token: 'OBO_CONSTRUCT_REFRESHTOKEN',
        elbridge_sessionInfo: 'OBO_CONSTRUCT_ELBRIDGE_SESSIONINFO',
        seen_motd_list: 'OBO_CONSTRUCT_SEENMOTD',
        admin_translation_columns: 'OBO_CONSTRUCT_TRANSLATIONCOLUMNS',
        portal_region: 'OBO_CONSTRUCT_PORTAL_REGION',
        language: 'OBO_CONSTRUCT_LANGUAGE',
        grounding_products: 'OBO_CONSTRUCT_GROUNDING_PRODUCTS',
        grounding_summary: 'OBO_CONSTRUCT_GROUNDING_SUMMARY',
        seen_tour_list: {
            dashboard: 'OBO_CONSTRUCT_DASHBOARD_TOUR',
            overvoltage: {
                entry: 'OBO_CONSTRUCT_OVERVOLTAGE_ENTRY_TOUR',
                metadata: 'OBO_CONSTRUCT_OVERVOLTAGE_METADATA_TOUR',
                dummy: 'OBO_CONSTRUCT_OVERVOLTAGE_DUMMY_TOUR',
                productSelection: 'OBO_CONSTRUCT_OVERVOLTAGE_PRODUCTSELECTION_TOUR',
                finishWizard: 'OBO_CONSTRUCT_OVERVOLTAGE_FINISHWIZARD_TOUR',
                overview: 'OBO_CONSTRUCT_OVERVOLTAGE_OVERVIEW_TOUR'
            },
            underfloor: {
                entry: 'OBO_CONSTRUCT_UNDERFLOOR_ENTRY_TOUR',
                floorParameterWizard: 'OBO_CONSTRUCT_UNDERFLOOR_FLOOR_PARAMETER_WIZARD_TOUR',
                socketConfigurator: 'OBO_CONSTRUCT_UNDERFLOOR_SOCKET_CONFIGURATOR_TOUR',
                deviceInsetConfigurator: 'OBO_CONSTRUCT_UNDERFLOOR_DEVICE_INSET_CONFIGURATOR_TOUR',
                socketConfiguratorAccessories: 'OBO_CONSTRUCT_UNDERFLOOR_SOCKET_CONFIGURATOR_ACCESSORIES_TOUR',
                finishWizardTour: 'OBO_CONSTRUCT_UNDERFLOOR_FINISH_WIZARD_TOUR',
                deviceInsetAccessoriesConfigurator: 'OBO_CONSTRUCT_UNDERFLOOR_DEVICE_INSET_ACCESSORIES_CONFIGURATOR_TOUR',
                overview: 'OBO_CONSTRUCT_UNDERFLOOR_OVERVIEW_TOUR',
                underfloorChannelConfigurator: 'OBO_CONSTRUCT_UNDERFLOOR_CHANNEL_CONFIGURATOR_TOUR'
            },
            fillingCalculator: {
                entry: 'OBO_CONSTRUCT_FILLING_CALCULATOR_ENTRY_TOUR',
                parameters: 'OBO_CONSTRUCT_FILLING_CALCULATOR_PARAMETERS_TOUR',
                productFinder: 'OBO_CONSTRUCT_FILLING_PRODUCT_FINDER_TOUR',
                advanced: 'OBO_CONSTRUCT_FILLING_CALCULATOR_ADVANCED_TOUR',
                finishWizard: 'OBO_CONSTRUCT_FILLING_FINISH_WIZARD_TOUR',
                overview: 'OBO_CONSTRUCT_FILLING_CALCULATOR_OVERVIEW_TOUR'
            },
            incendiary: {
                entry: 'OBO_CONSTRUCT_INCENDIARY_ENTRY_TOUR',
                wallBreachParameters: 'OBO_CONSTRUCT_INCENDIARY_WALL_BREACH_PARAMETERS_TOUR',
                bulkheadSystems: 'OBO_CONSTRUCT_INCENDIARY_BULKHEAD_SYSTEMS_TOUR',
                finishWizard: 'OBO_CONSTRUCT_INCENDIARY_FINISH_WIZARD_TOUR',
                overview: 'OBO_CONSTRUCT_INCENDIARY_OVERVIEW_TOUR',
                documentation: 'OBO_CONSTRUCT_INCENDIARY_DOCUMENTATION_TOUR',
                accessories: 'OBO_CONSTRUCT_INCENDIARY_ACCESSORIES_TOUR'
            },
            grounding: {
                entry: 'OBO_CONSTRUCT_GROUNDING_ENTRY_TOUR',
                typeSelector: 'OBO_CONSTRUCT_GROUNDING_TYPE_SELECTOR_TOUR',
                groundingProductSelector: 'OBO_CONSTRUCT_GROUNDING_PRODUCT_SELECTOR_TOUR',
                groundingSummary: 'OBO_CONSTRUCT_GROUNDING_SUMMARY_TOUR'
            },
            photovoltaic: {
                location: 'OBO_CONSTRUCT_PHOTOVOLTAIC_LOCATION_TOUR',
                weatherData: 'OBO_CONSTRUCT_PHOTOVOLTAIC_WEATHER_DATA_TOUR',
                roofParameters: 'OBO_CONSTRUCT_PHOTOVOLTAIC_ROOF_PARAMETERS_TOUR',
                obstacleConfiguration: 'OBO_CONSTRUCT_PHOTOVOLTAIC_OBSTACLE_CONFIGURATION_TOUR',
                moduleConfiguration: 'OBO_CONSTRUCT_PHOTOVOLTAIC_MODULE_CONFIGURATION_TOUR',
                productSelection: 'OBO_CONSTRUCT_PHOTOVOLTAIC_PRODUCT_SELECTION_TOUR',
                finishWizard: 'OBO_CONSTRUCT_PHOTOVOLTAIC_FINISH_WIZARD_TOUR',
                overview: 'OBO_CONSTRUCT_PHOTOVOLTAIC_OVERVIEW_TOUR'
            }
        }
    };

    export const elbridgeInterfaceAddress = 'https://interface.elbridge.etim-deutschland.de/';

    export enum Role {
        Guest = 'Guest',
        User = 'User',
        AccountAdmin = 'AccountAdmin',
        ContentAdmin = 'ContentAdmin',
        ProductAdmin = 'ProductAdmin',
        AnalyticsAdmin = 'AnalyticsAdmin'
    }

    export enum ModuleId {
        Incendiary = 453783,
        Filling = 468732,
        Underfloor = 444497,
        Grounding = 443968,
        Overvoltage = 443696,
        Photovoltaic = 443837,
        Egress = 437445
    }

    export const PortalUrls: { [key: number]: string } = {
        1: 'cable-support-systems',
        2: 'fire-protection-sealing',
        3: 'fire-protection-systems',
        4: 'fire-protection-systems-app',
        5: 'cablefilling',
        6: 'cable-assignment',
        7: 'escape-rescue-route-systems',
        8: 'photovoltaic-systems',
        9: 'earthing-systems',
        10: 'overvoltage-protection-systems',
        11: 'surge-protection-systems',
        12: 'underfloor-installations',
        13: 'underfloor-systems'
    };

    export const PortalFeaturesToModules: { [key: number]: number } = {
        2: ModuleId.Incendiary,
        5: ModuleId.Filling,
        7: ModuleId.Egress,
        8: ModuleId.Photovoltaic,
        9: ModuleId.Grounding,
        10: ModuleId.Overvoltage,
        12: ModuleId.Underfloor
    };

    export const Symbols = {
        booleanTrue: '🗸',
        booleanFalse: '×'
    };

    export const DesktopOnlyModules = [ModuleId.Photovoltaic];

    export const NonImportExportModules = [ModuleId.Filling];

    export const RegionNames = {
        Germany: 'DE'
    };

    export const ValidationPattern = {
        Naming: '^(?!\\s*$)[-äÄüÜöÖa-zA-Z0-9_\\-.\\s]*$',
        Email: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$'
    };

    export const NAV_HEIGHT_IN_PX = 60;

    export const INCENDIARY_MAX_SYSTEM_ROOM_COUNT = 50;
}
