import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class CacheService {
    constructor(@Inject('APIPREFIX') private apiPrefix: string, private http: HttpClient) {}

    public clearResponseCache(): Observable<any> {
        return this.http.post(`${this.apiPrefix}OutputCaching/Purge/tag-all`, {});
    }

    public clearResourceCache(): Observable<any> {
        return this.http.post(`${this.apiPrefix}ResourceCaching/Purge`, {});
    }
}
