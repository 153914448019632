<form name="registerForm" id="register-form" (ngSubmit)="register(registerForm.value)" role="form" #registerForm="ngForm">
    <div class="mb-3 required">
        <label class="form-label" for="email">
            {{ 'REGISTERFORM_EMAIL' | translate }}
        </label>
        <input
            ngModel
            type="email"
            name="email"
            id="email"
            tabindex="0"
            class="form-control form-control-border"
            required
            shdValidateInput
            shdValidateEmail/>
    </div>

    <div class="mb-3 required">
        <shd-single-select
            [(ngModel)]="selectedPortalRegion"
            [withBorder]="true"
            required
            shdValidateInput
            [ngModelOptions]="{ standalone: true }">
            <ng-template shdSingleSelectLabel>
                <span class="flag-icon flag-icon-{{ selectedPortalRegion?.name | lowercase }} mr-2"></span>
                <span>
                    {{ (selectedPortalRegion ? selectedPortalRegion.displayName : '-') | translate }}
                </span>
            </ng-template>
            @for (region of regionList; track region) {
            <ng-template shdSingleSelectOption [value]="region">
                <span class="flag-icon flag-icon-{{ region.name | lowercase }} mr-2"></span>
                <span>
                    {{ region.displayName | translate }}
                </span>
            </ng-template>
            }
        </shd-single-select>
    </div>

    <div class="mb-3 required">
        <label class="form-label" for="password">
            {{ 'REGISTERFORM_PASSWORD' | translate }}
        </label>
        <div class="position-relative">
            <input
                ngModel
                type="password"
                shdToggle
                name="password"
                id="password"
                tabindex="0"
                class="form-control form-control-border"
                [pattern]="passwordPattern"
                required
                shdValidateEqual="confirmPassword"
                reverse="true"
                shdValidateInput
                [customErrorMsg]="'REGISTERFORM_PASSWORDPATTERN_ERROR' | translate" />
        </div>
    </div>
    <div class="mb-3 required">
        <label class="form-label" for="confirmPassword">
            {{ 'REGISTERFORM_PASSWORDCONFIRM' | translate }}
        </label>
        <div class="position-relative">
            <input
                ngModel
                type="password"
                shdToggle
                name="confirmPassword"
                id="confirmPassword"
                tabindex="0"
                class="form-control form-control-border"
                shdValidateEqual="password"
                required
                shdValidateInput
                [customErrorMsg]="'REGISTERFORM_PASSWORDCONFIRM_ERROR' | translate" />
        </div>
    </div>
    <div class="mb-3">
        <div class="form-check">
            <input type="checkbox" [ngModel]="false" name="isContactable" id="isContactable" class="form-check-input" />
            <label class="form-check-label text-muted" for="isContactable">
                {{ 'REGISTERFORM_ALLOWCONTACT' | translate }}
            </label>
        </div>
    </div>
    <div class="mb-3">
        <div class="form-check required">
            <input type="checkbox" id="acceptTos" ngModel name="acceptTos" class="form-check-input" required />
            <label class="form-check-label text-muted tos" for="acceptTos">
                <span [innerHTML]="tosAcceptText | safeHTML"></span>
                *
            </label>
        </div>
    </div>
    <shd-recaptcha [(valid)]="reCaptchaValid"></shd-recaptcha>
    <small class="form-text text-muted">
        {{ 'SHARED_REQUIRED_FIELD_INFO' | translate }}
    </small>
    <div class="my-3">
        <button
            type="submit"
            name="register-submit"
            id="register-submit"
            tabindex="0"
            class="btn btn-success"
            [disabled]="
                registerForm.invalid || !reCaptchaValid
            ">
            {{ 'REGISTERFORM_BTN_REGISTER' | translate }}
        </button>
    </div>
</form>
