import { OverlayConfig, OverlayPositionBuilder, ScrollStrategyOptions } from '@angular/cdk/overlay';

export function overlayConfigFactory(positionBuilder: OverlayPositionBuilder, scrollStrategyOptions: ScrollStrategyOptions) {
    return {
        width: '90vw',
        maxWidth: '1500px',
        height: '90vh',
        maxHeight: '1000px',
        hasBackdrop: true,
        positionStrategy: positionBuilder.global().centerHorizontally().centerVertically(),
        scrollStrategy: scrollStrategyOptions.block(),
        panelClass: ['overlay-container']
    } as OverlayConfig;
}

export function groundingProductSelectorModalFactory(
    positionsBuilder: OverlayPositionBuilder,
    scrollStrategyOptions: ScrollStrategyOptions
) {
    return {
        width: '45vw',
        minWidth: '350px',
        maxWidth: '45vw',
        maxHeight: '90vh',
        hasBackdrop: true,
        positionStrategy: positionsBuilder.global().centerHorizontally().centerVertically(),
        scrollStrategy: scrollStrategyOptions.block(),
        panelClass: ['overlay-container']
    } as OverlayConfig;
}

export function customCableModalFactory(positionsBuilder: OverlayPositionBuilder, scrollStrategyOptions: ScrollStrategyOptions) {
    return {
        width: '600px',
        maxWidth: '90vw',
        maxHeight: '90vh',
        hasBackdrop: true,
        positionStrategy: positionsBuilder.global().centerHorizontally().centerVertically(),
        scrollStrategy: scrollStrategyOptions.block(),
        panelClass: ['overlay-container']
    } as OverlayConfig;
}

export function zoomedImageModalFactory(positionsBuilder: OverlayPositionBuilder, scrollStrategyOptions: ScrollStrategyOptions) {
    return {
        maxWidth: '90vw',
        maxHeight: '90vh',
        height: '500px',
        hasBackdrop: true,
        positionStrategy: positionsBuilder.global().centerHorizontally().centerVertically(),
        scrollStrategy: scrollStrategyOptions.block(),
        panelClass: ['overlay-container']
    } as OverlayConfig;
}

export function overlayXSFactory(positionsBuilder: OverlayPositionBuilder, scrollStrategyOptions: ScrollStrategyOptions) {
    return {
        maxWidth: '90vw',
        maxHeight: '90vh',
        height: '300px',
        width: '400px',
        hasBackdrop: true,
        positionStrategy: positionsBuilder.global().centerHorizontally().centerVertically(),
        scrollStrategy: scrollStrategyOptions.block(),
        panelClass: ['overlay-container']
    } as OverlayConfig;
}

export function overlaySFactory(positionsBuilder: OverlayPositionBuilder, scrollStrategyOptions: ScrollStrategyOptions) {
    return {
        maxWidth: '90vw',
        maxHeight: '90vh',
        width: '500px',
        hasBackdrop: true,
        positionStrategy: positionsBuilder.global().centerHorizontally().centerVertically(),
        scrollStrategy: scrollStrategyOptions.block(),
        panelClass: ['overlay-container']
    } as OverlayConfig;
}

export function overlayLFactory(positionsBuilder: OverlayPositionBuilder, scrollStrategyOptions: ScrollStrategyOptions) {
    return {
        width: '1000px',
        maxWidth: '90vw',
        maxHeight: '90vh',
        height: '90vh',
        hasBackdrop: true,
        positionStrategy: positionsBuilder.global().centerHorizontally().centerVertically(),
        scrollStrategy: scrollStrategyOptions.block(),
        panelClass: ['overlay-container']
    } as OverlayConfig;
}

export function overlayXLFactory(positionsBuilder: OverlayPositionBuilder, scrollStrategyOptions: ScrollStrategyOptions) {
    return {
        width: '90vw',
        maxWidth: '90vw',
        maxHeight: '90vh',
        hasBackdrop: true,
        positionStrategy: positionsBuilder.global().centerHorizontally().centerVertically(),
        scrollStrategy: scrollStrategyOptions.block(),
        panelClass: ['overlay-container']
    } as OverlayConfig;
}

export function overlayNoProductsFactory(positionsBuilder: OverlayPositionBuilder, scrollStrategyOptions: ScrollStrategyOptions) {
    return {
        width: '50vw',
        maxWidth: '90vw',
        maxHeight: '75vh',
        height: '40vh',
        hasBackdrop: true,
        positionStrategy: positionsBuilder.global().centerHorizontally().centerVertically(),
        scrollStrategy: scrollStrategyOptions.block(),
        panelClass: ['overlay-container']
    } as OverlayConfig;
}

export function overlayRegionLanguageSelectionFactory(
    positionsBuilder: OverlayPositionBuilder,
    scrollStrategyOptions: ScrollStrategyOptions
) {
    return {
        width: '40vw',
        maxWidth: '90vw',
        maxHeight: '90vh',
        height: '70vh',
        hasBackdrop: true,
        positionStrategy: positionsBuilder.global().centerHorizontally().centerVertically(),
        scrollStrategy: scrollStrategyOptions.block(),
        panelClass: ['overlay-container', 'regionLanguageSelectorModal']
    } as OverlayConfig;
}

export function overlayFullScreenFactory(positionsBuilder: OverlayPositionBuilder, scrollStrategyOptions: ScrollStrategyOptions) {
    return {
        width: '100vw',
        height: '100vh',
        hasBackdrop: true,
        positionStrategy: positionsBuilder.global().centerHorizontally().centerVertically(),
        scrollStrategy: scrollStrategyOptions.block(),
        panelClass: ['overlay-container']
    } as OverlayConfig;
}

export function overlayQuantityCombinationFactory(
    positionsBuilder: OverlayPositionBuilder,
    scrollStrategyOptions: ScrollStrategyOptions
) {
    return {
        width: '1000px',
        maxWidth: '90vw',
        maxHeight: '90vh',
        hasBackdrop: true,
        positionStrategy: positionsBuilder.global().centerHorizontally().centerVertically(),
        scrollStrategy: scrollStrategyOptions.block(),
        panelClass: ['overlay-container']
    } as OverlayConfig;
}
