<div id="translation-management">
    <adm-header [title]="'NAVIGATION_TRANSLATION' | translate">
        <ng-template admHeaderToolbar>
            <div class="d-flex">
                <shd-multi-filter-select
                    [withBorder]="true"
                    class="mx-3"
                    style="width: 250px"
                    [filter]="filterFormGroup.controls.languages"
                    [applyBtnText]="'BTN_APPLY' | translate"
                    [selectAllBtnText]="'CHECK_ALL' | translate"
                    [unselectAllBtnText]="'UNCHECK_ALL' | translate"></shd-multi-filter-select>
                <shd-multi-filter-select
                    [withBorder]="true"
                    style="width: 250px"
                    [filter]="filterFormGroup.controls.modules"
                    [applyBtnText]="'BTN_APPLY' | translate"
                    [selectAllBtnText]="'CHECK_ALL' | translate"
                    [unselectAllBtnText]="'UNCHECK_ALL' | translate"></shd-multi-filter-select>
                <div class="d-flex items-align-center ml-auto">
                    <label class="btn btn-fa mx-3 h-100" for="file">
                        <fa-icon [icon]="['fal', 'upload']" class="me-3"></fa-icon>
                        {{ 'ADMIN_IMPORT_XLIFF_BTN' | translate }}
                        <input
                            type="file"
                            id="file"
                            #file
                            name="file"
                            multiple
                            class="d-none"
                            (click)="$event.target.value = null"
                            (change)="selectFiles($event)"
                            accept=".xlf, .xliff" />
                    </label>
                    <button class="btn btn-fa" type="button" (click)="openTranslationExportModal()">
                        <fa-icon [icon]="['fal', 'download']" class="me-3"></fa-icon>
                        {{ 'ADMIN_EXPORT_XLIFF_BTN' | translate }}
                    </button>
                </div>
            </div>
        </ng-template>
        <ng-template admHeaderActionButtons>
            <button [disabled]="disabled" class="btn btn-secondary btn-admin-cache" (click)="clearCache()">
                {{ 'ADMIN_CLEAR_CACHE' | translate }}
            </button>
            <button class="btn btn-fa" [ngbTooltip]="'ADMIN_ADD_NEW' | translate" (click)="addHandler()">
                <fa-icon [icon]="['fal', 'plus']" size="2x"></fa-icon>
            </button>
        </ng-template>
    </adm-header>
    <adm-data-grid (selectionChange)="selectRow($event)" #grid [data]="data()" [isClientSide]="true">
        <adm-data-grid-column field="key" width="250" [title]="'ADMIN_TRANSLATION_KEY' | translate">
            <ng-template admDataGridFilterMenu let-field="field" let-filter="filter">
                <adm-data-grid-filter-input [field]="field" [filter]="filter"></adm-data-grid-filter-input>
            </ng-template>
        </adm-data-grid-column>
        <adm-data-grid-column
            field="moduleId"
            [title]="'ADMINS_PORTAL_MODULE' | translate"
            width="150"
            [filterable]="false"
            [isMulti]="true">
            <ng-template admDataGridCell let-dataItem>
                {{ moduleMap[dataItem.moduleId] }}
            </ng-template>
        </adm-data-grid-column>
        <adm-data-grid-column
            field="modificationDate"
            [title]="'ADMIN_MODIFICATIONDATE' | translate"
            width="150"
            [filterable]="false">
            <ng-template admDataGridCell let-dataItem>
                {{ dataItem.modificationDate | date : 'mediumDate' }}
            </ng-template>
        </adm-data-grid-column>
        @for (culture of cultures; track $index) {
        <adm-data-grid-column [field]="culture.name" width="200" [title]="culture.name | uppercase">
            <ng-template admDataGridFilterMenu let-field="field" let-filter="filter">
                <adm-data-grid-filter-input [field]="field" [filter]="filter"></adm-data-grid-filter-input>
            </ng-template>
        </adm-data-grid-column>
        }
    </adm-data-grid>
</div>

<ng-template #editTranslationModalTpl let-modal>
    <div class="modal border d-flex flex-column">
        <div class="modal-header">
            <h2 class="m-0">
                {{ 'ADMIN_TRANSLATIONS_EDIT_TITLE' | translate }}
            </h2>
            <button type="button" class="btn-close" (click)="modal.dismiss(); cancelHandler()"></button>
        </div>
        <div class="modal-body translations-modal-body">
            <form [formGroup]="formGroup" class="h-100 d-flex flex-column">
                <div class="flex-shrink-auto">
                    <div class="mb-2">
                        @if (enableHTML) {
                        <shd-infobox
                            [headerText]="'ADMIN_TRANSLATION_HTML_HINT_TITLE' | translate"
                            [bodyText]="'ADMIN_TRANSLATION_HTML_HINT' | translate"></shd-infobox>
                        }
                    </div>
                    <div class="row mb-4">
                        <div class="col-12 col-lg-3">
                            <label for="moduleId">
                                {{ 'ADMINS_PORTAL_MODULE' | translate }}
                            </label>
                            <shd-single-select id="moduleId" formControlName="moduleId">
                                @for (module of modules; track $index) {
                                <ng-template shdSingleSelectLabel>
                                    {{ moduleMap[formGroup.controls.moduleId.value] }}
                                </ng-template>
                                <ng-template shdSingleSelectOption [value]="module.id.toString()">
                                    {{ module.name }}
                                </ng-template>
                                }
                            </shd-single-select>
                        </div>
                        <div class="col-12 col-lg-9 d-flex align-items-end">
                            <div class="w-100">
                                <label for="key">
                                    {{ 'ADMIN_TRANSLATION_KEY' | translate }}
                                </label>
                                <input class="form-control" id="key" type="text" formControlName="key" />
                            </div>
                            @if (selectedRow) {
                            <button
                                class="btn btn-secondary btn-sm d-flex align-items-center h-60 ms-2"
                                type="button"
                                (click)="modal.close(); removeRow(selectedRow)">
                                <fa-icon [icon]="['fal', 'trash-alt']" size="lg"></fa-icon>
                                {{ 'ADMIN_DELETE' | translate }}
                            </button>
                            }
                        </div>
                    </div>
                </div>
                <div class="row flex-grow-shrink-auto h-75">
                    <div class="col-12 col-lg-3 h-100">
                        <div class="d-flex flex-column h-100">
                            <div class="flex-shrink-auto">
                                <div class="mb-3 w-100 mx-1">
                                    <label class="switch d-flex justify-content-between">
                                        <span class="font-weight-bold">
                                            {{ 'ADMIN_TRANSLATION_EDITORTYPE_HTML' | translate }}
                                        </span>
                                        <input
                                            type="checkbox"
                                            [(ngModel)]="enableHTML"
                                            [ngModelOptions]="{
                                                standalone: true
                                            }" />
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                                <input
                                    class="form-control mb-1"
                                    type="text"
                                    (keyup)="filterCultures($event.target.value)"
                                    [attr.placeholder]="'SHARED_SEARCH_DOTS' | translate" />
                            </div>
                            <div class="languages-content flex-grow-shrink-auto webkit-scrollbar p-1">
                                @for (culture of filteredCultures; track $index) {
                                <div class="form-check mb-2">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        [(ngModel)]="culture.active"
                                        [ngModelOptions]="{ standalone: true }"
                                        (ngModelChange)="updateFormGroup(culture.name, $event)"
                                        [attr.id]="culture.name" />
                                    <label class="form-check-label" [attr.for]="culture.name">
                                        {{ culture.displayName | translate }}
                                    </label>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-9 h-100">
                        <ul ngbNav class="nav-tabs" #translationLanguagesNav="ngbNav">
                            @for (control of formGroup?.controls | keyvalue; track $index) { @if
                            (getControl(control.value).enabled && control.key !== 'key' && control.key !== 'moduleId' &&
                            control.key !== 'modificationDate') {
                            <li ngbNavItem [domId]="control.key">
                                <a [ngClass]="{ 'text-danger': getControl(control.value).invalid }" ngbNavLink>
                                    {{ getKey(control.key) | uppercase }}
                                </a>
                                <ng-template ngbNavContent>
                                    @if (!enableHTML) {
                                    <div class="mb-3 h-100">
                                        <textarea
                                            class="form-control text-break translations-content webkit-scrollbar translation-text-area"
                                            type="text"
                                            [formControl]="control.value"
                                            [pattern]="xmlCharactersPattern"></textarea>
                                    </div>
                                    } @else {
                                    <div>
                                        <editor
                                            [init]="{
                                                entity_encoding: 'raw',
                                                element_format: 'xhtml',
                                                menubar: false,
                                                height: 'calc(75vh - 290px)',
                                                width: '100%',
                                                plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount'
                                                ],
                                                toolbar:
                                                    'undo redo | formatselect | bold italic backcolor | \
													  alignleft aligncenter alignright alignjustify | \
													  bullist numlist outdent indent | removeformat | link | help'
                                            }"
                                            [formControl]="control.value"></editor>
                                    </div>
                                    }
                                </ng-template>
                            </li>
                            } }
                        </ul>
                        <div [ngbNavOutlet]="translationLanguagesNav"></div>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer align-items-center justify-content-between mt-3">
            <button class="btn btn-step-back" type="button" (click)="modal.dismiss(); cancelHandler()">
                <fa-icon [icon]="['fal', 'chevron-left']" size="2x"></fa-icon>
                {{ 'BTN_ABORT' | translate }}
            </button>
            @if (formGroup?.invalid && !enableHTML) {
            <span
                class="form-text text-danger col-8 text-center"
                [innerHTML]="'ADMIN_TRANSLATION_INVALID_CHARACTERS_MESSAGE' | translate"></span>
            }
            <button class="btn btn-success" type="button" (click)="modal.close(); saveHandler()" [disabled]="formGroup?.invalid">
                {{ 'BTN_SAVE' | translate }}
            </button>
        </div>
    </div>
</ng-template>
