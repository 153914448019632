<div id="app-header" class="fixed-top">
    <ng-content></ng-content>
    <nav shdClickOutside (clickOutside)="closeNavbar()" class="navbar navbar-sidebar">
        <button shdGoBack class="btn btn-fa text-white d-flex d-lg-none">
            <fa-icon [icon]="['fal', 'chevron-left']" size="2x"></fa-icon>
        </button>
        <a class="navbar-brand company-logo mx-2" uiSref="portal" (click)="navbarOpen = false"></a>
        <div class="d-flex align-items-center">
            <div class="d-none d-lg-block me-4">
                @if (!isLoggedIn) {
                <button class="btn-login justify-content-center px-4" id="login-panel-dropdown" uiSref="login">
                    <fa-icon [icon]="['fal', 'sign-in']" size="lg"></fa-icon>
                    {{ 'NAVIGATION_LOGIN' | translate }}
                </button>
                }
            </div>
            <button
                class="navbar-toggler navbar-toggler-right btn-fa text-white d-flex align-items-center"
                type="button"
                (click)="navbarOpen = !navbarOpen"
                aria-expanded="false"
                aria-controls="navbarContent"
                aria-label="Toggle navigation"
                #toggleSideBarButton>
                <span class="d-none d-lg-inline me-4">{{ 'NAVIGATION_MENU' | translate }}</span>
                <fa-icon [icon]="['fal', 'bars']" size="2x"></fa-icon>
            </button>
        </div>
        @if (navbarOpen) {
        <div class="navbar-collapse" id="navbarContent" @flyOutFlyIn>
            <div class="w-100 d-flex justify-content-end">
                <button class="navbar-toggler navbar-toggler-right me-0 px-1" (click)="navbarOpen = !navbarOpen">
                    <span class="btn btn-fa btn-color-orange me-2">
                        <fa-icon [icon]="['fal', 'bars']" size="2x"></fa-icon>
                    </span>
                </button>
            </div>
            <ul class="navbar-nav me-auto">
                <h6 class="nav-header">
                    {{ 'NAVIGATION_MY_CONSTRUCT' | translate }}
                </h6>
                @for (item of navMyConstruct; track item.name) {
                <ng-container *shdCheckRoles="item.roles">
                    <li
                        class="nav-item nav-border-bottom"
                        [ngClass]="{
                            active: checkActiveMarker(item)
                        }"
                        *shdCheckFeatures="item.features">
                        <a class="nav-link" [uiSref]="item.uiSref" (click)="navbarOpen = false">
                            <div class="d-flex">
                                <span class="icon">
                                    <fa-icon [icon]="item.faIcon" size="lg"></fa-icon>
                                </span>
                                {{ item.key | translate }}
                            </div>
                        </a>
                    </li>
                </ng-container>
                }
            </ul>
            <ul class="navbar-nav mb-4">
                <h6 class="nav-header">
                    {{ 'NAVIGATION_ABOUT_US' | translate }}
                </h6>
                @for (item of navAboutUs; track item.name) {
                <li
                    class="nav-item nav-border-bottom"
                    [ngClass]="{ active: checkActiveMarker(item) }"
                    *shdCheckRoles="item.roles">
                    <a class="nav-link" [uiSref]="item.uiSref" (click)="navbarOpen = false">
                        <div class="d-flex">
                            <span class="icon">
                                <fa-icon [icon]="item.faIcon" size="lg"></fa-icon>
                            </span>
                            {{ item.key | translate }}
                        </div>
                    </a>
                </li>
                }
                <!--User Centrics Popup needs a special treatment since it uses href to open the
                                modal instead of uiSref which the components in the "mobileNavAboutUs" Array use-->
                <li class="nav-item nav-border-bottom">
                    <a
                        class="nav-link"
                        href="#"
                        onclick="UC_UI.showSecondLayer();"
                        (click)="navbarOpen = false; $event.preventDefault()">
                        <div class="d-flex">
                            <span class="icon">
                                <fa-icon [icon]="['fal', 'user-secret']" size="lg"></fa-icon>
                            </span>
                            {{ 'FOOTER_PRIVACY_SETTINGS' | translate }}
                        </div>
                    </a>
                </li>
            </ul>

            <ul class="navbar-nav navbar-bottom">
                <li class="nav-item split-item nav-border-bottom">
                    <a class="nav-link" uiSref="accountOverview" *shdCheckRoles="['User']">
                        <span class="icon">
                            <fa-icon [icon]="['fal', 'user']" size="lg"></fa-icon>
                        </span>
                    </a>
                    <a class="nav-link" (click)="openLanguageSelector()">
                        <span class="icon">
                            <span class="flag-icon flag-icon-{{ selectedRegion?.name | lowercase }} me-2"></span>
                        </span>
                    </a>
                </li>
                @if (isLoggedIn) {
                <li class="nav-item">
                    <a class="nav-link" (click)="logout()">
                        <div class="d-flex">
                            <span class="icon">
                                <fa-icon [icon]="['fal', 'sign-out']" size="lg"></fa-icon>
                            </span>
                            {{ 'NAVIGATION_LOGOUT' | translate }}
                        </div>
                    </a>
                </li>
                }
            </ul>
            <ul class="navbar-nav">
                <li class="nav-item nav-text">
                    @if (buildInfo && (debugMode || ('settings.show_buildInfo' | appSettings))) {
                    <div class="small hidden-sr d-none d-lg-flex">
                        {{ buildInfo }}
                    </div>
                    } &copy; {{ currentYear }} OBO Bettermann Holding GmbH & Co. KG
                </li>
            </ul>
        </div>
        }
    </nav>
</div>
