<div class="data-grid">
    @if (table) {
        <div class="table-data-grid-container webkit-scrollbar">
            <table class="table mb-0 table-striped" [class.table-hover]="selectable">
                <thead>
                    @for (headerGroup of table.getHeaderGroups(); track headerGroup.id) {
                        <tr>
                            @for (header of headerGroup.headers; track header.id) {
                                <th [attr.colSpan]="header.colSpan" [style.min-width.px]="header.getSize()">
                                <span class="data-grid-table-header-column">
                                    <span class="data-grid-table-header-column-title"
                                          [class.sortable]="header.column.columnDef.meta.sortable"
                                          (click)="header.column.toggleSorting()">
                                        {{ header.column.columnDef.header }}
                                    </span>
                                    <span class="data-grid-table-header-column-actions">
                                    @if (header.column.getIsSorted() === 'asc') {
                                        <fa-icon class="ms-2" [icon]="['fas','arrow-up']" size="sm"></fa-icon>
                                    }
                                        @if (header.column.getIsSorted() === 'desc') {
                                            <fa-icon class="ms-2" [icon]="['fas','arrow-down']" size="sm"></fa-icon>
                                        }
                                        @if (header.column.columnDef.meta.filterable) {
                                            <adm-data-grid-filter [field]="header.column.columnDef.id"
                                                                  [filterMenuTemplate]="header.column.columnDef.meta.filterMenuTemplate"
                                                                  [filterService]="filterService"
                                                                  [type]="header.column.columnDef.meta.type"></adm-data-grid-filter>
                                        }
                                    </span>
                                    </span>
                                </th>
                            }
                        </tr>
                    }
                </thead>
                <tbody>
                    @for (row of table.getRowModel()?.rows; track row.id) {
                        <tr (click)="selectionChange.emit(row.original)">
                            @for (cell of row.getVisibleCells(); track $index) {
                                <td>
                                    @if (cell.column.columnDef.meta.cellTemplate) {
                                        <ng-template [ngTemplateOutlet]="cell.column.columnDef.meta.cellTemplate"
                                                     [ngTemplateOutletContext]="{$implicit: row.original}"></ng-template>
                                    } @else {
                                        {{ cell.getValue() }}
                                    }
                                </td>
                            }
                        </tr>
                    }
                </tbody>
            </table>
        </div>
        <div class="pagination-container">
            <div class="pagination-actions">
                <button class="btn btn-pagination"
                        [disabled]="currentPage === 1" (click)="firstPage()">
                    <fa-icon [icon]="['fas', 'left-to-line']"></fa-icon>
                </button>
                <button class="btn btn-pagination" (click)="table.previousPage()"
                        [disabled]="currentPage === 1">
                    <fa-icon [icon]="['fas', 'left']"></fa-icon>
                </button>
                <div class="pagination-state">
                    <span>Page</span>
                    <input min="0" [max]="maxPageSize" type="number" class="form-control form-control-border"
                           [value]="currentPage" (keyup.enter)="toPage($event)" />
                    <span>of</span>
                    <span>{{ maxPageSize }}</span>
                </div>
                <button class="btn btn-pagination" (click)="nextPage(1)"
                        [disabled]="currentPage === maxPageSize">
                    <fa-icon [icon]="['fas', 'right']"></fa-icon>
                </button>
                <button class="btn btn-pagination"
                        [disabled]="currentPage === maxPageSize"
                        (click)="lastPage()">
                    <fa-icon [icon]="['fas', 'right-to-line']"></fa-icon>
                </button>
                <div class="pagination-size">
                    <shd-single-select [(ngModel)]="dataGridPageSize" withBorder="true"
                                       placement="top-left">
                        <ng-template shdSingleSelectLabel>
                            {{ pageSize }}
                        </ng-template>
                        @for (pageSize of pageSizes; track $index) {
                            <ng-template [value]="pageSize" shdSingleSelectOption (selected)="changePageSize(pageSize)">
                                {{ pageSize }}
                            </ng-template>
                        }
                    </shd-single-select>
                    <span>items per page</span>
                </div>
            </div>
            <div class="pagination-summary">
                {{ summary }}
            </div>
        </div>
    }
</div>
