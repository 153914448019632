<div id="culture-management">
    <adm-header [title]="'NAVIGATION_LANGUAGES' | translate">
        <ng-template admHeaderActionButtons>
            <button class="btn btn-fa" [ngbTooltip]="'ADMIN_ADD_NEW' | translate" (click)="createCulture()">
                <fa-icon [icon]="['fal', 'plus']" size="2x"></fa-icon>
            </button>
            <button class="btn btn-fa" [ngbTooltip]="'ADMIN_EXPORT_EXCEL_BTN' | translate"
                    (click)="grid.exportExcel()">
                <fa-icon [icon]="['fal', 'file-excel']" size="2x"></fa-icon>
            </button>
        </ng-template>
    </adm-header>
    <adm-data-grid (selectionChange)="selectRow($event)" [data]="dataGridService | async" #grid
                   (excelExport)="onExcelExport($event)" [dataGridState]="dataGridState">
        <adm-data-grid-column field="name" title="{{ 'LANGUAGES_NAME' | translate }}">
        </adm-data-grid-column>
        <adm-data-grid-column
            field="displayName"
            [filterable]="false"
            [sortable]="false"
            title="{{ 'LANGUAGES_DISPLAYNAME' | translate }}">
        </adm-data-grid-column>
        <adm-data-grid-excel fileName="languages.xlsx"></adm-data-grid-excel>
    </adm-data-grid>
</div>

<ng-template #languageModal>
    <div class="modal">
        <div class="modal-header">
            <h3 class="mb-0">{{ 'LANGUAGES_NAME' | translate }}</h3>
            <button class="btn-close" (click)="cancelHandler()"></button>
        </div>
        <div class="modal-body">
            <form [formGroup]="formGroup">
                <div class="mb-3">
                    <label for="name">{{ 'LANGUAGES_NAME' | translate }}</label>
                    <input class="form-control" type="text" id="name" formControlName="name" />
                </div>
                <div class="mb-3">
                    <label for="displayName">
                        {{ 'LANGUAGES_DISPLAYNAME' | translate }}
                    </label>
                    <input class="form-control" type="text" id="displayName" formControlName="displayName" />
                </div>
                @if (formModules.controls.length > 0) {
                    <div class="mb-3">
                        <h4>{{ 'ADMIN_MODULESTATUS' | translate }}</h4>
                        <ul class="list-unstyled">
                            @for (control of formModules.controls; track control; let i = $index) {
                                <li formGroupName="modules">
                                    <div class="form-check" [formGroupName]="i">
                                        <input
                                            type="checkbox"
                                            formControlName="isActive"
                                            [attr.id]="'module-' + i"
                                            class="form-check-input" />
                                        <label class="form-check-label text-muted" style="line-height: 25px"
                                               [attr.for]="'module-' + i">
                                            {{ control.value.name }}
                                        </label>
                                    </div>
                                </li>
                            }
                        </ul>
                    </div>
                }
            </form>
        </div>
        <div class="modal-footer">
            <div class="d-flex justify-content-between w-100">
                <button class="btn btn-secondary" type="button" (click)="cancelHandler()">
                    {{ 'BTN_ABORT' | translate }}
                </button>
                <button class="btn btn-secondary" type="button" (click)="confirmModalRef.show()">
                    {{ 'BTN_DELETE' | translate }}
                </button>
                <button class="btn btn-success" type="button" (click)="saveHandler()">
                    {{ 'BTN_SAVE' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<shd-confirm-modal
    #confirmModalRef
    (confirmed)="deleteCulture(selectedRow)"
    [title]=" 'ADMIN_ARE_YOU_SURE' | translate"></shd-confirm-modal>
