<section id="static-contact">
    <div class="row">
        <div class="col-12 col-lg-8">
            <shd-headline [headline]="'STATIC_CONTACT_TITLE'" [subHeadline]="'STATIC_CONTACT_SUBTITLE'"></shd-headline>
            <div class="row">
                <div class="col">
                    <p>
                        {{ 'STATIC_CONTACT_OBO_WORLDWIDE_DESCRIPTION' | translate }}
                    </p>
                    <a
                        class="btn btn-more-details-small"
                        href="https://obo-bettermann.com/en-wo/support/OBO-worldwide-5051.html"
                        target="_blank">
                        {{ 'STATIC_CONTACT_OBO_WORLDWIDE' | translate }}
                        <fa-icon class="ms-2" [icon]="['fal', 'arrow-up-right-from-square']"></fa-icon>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<h2 class="my-4">{{ 'STATIC_CONTACT_CONTACTFORM' | translate }}</h2>
<ui-view name="form"></ui-view>
