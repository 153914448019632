import { Component, Input, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ModalService } from '@obo-main/services/modal/modal.service';

@Component({
    selector: 'shd-info-button',
    templateUrl: './infoButton.component.html',
    styleUrls: ['./infoButton.component.scss']
})
export class InfoButtonComponent {
    @Input()
    public text: string;
    @Input()
    public title: string;
    @Input()
    public placement: string = 'auto';
    @Input()
    public color: string = '#000000';

    @ViewChild('infoModal')
    modal: TemplateRef<any>;

    constructor(private modalService: ModalService, private viewContainerRef: ViewContainerRef) {}

    public openModal(): void {
        this.modalService.open(this.modal, { viewContainerRef: this.viewContainerRef });
    }
}
