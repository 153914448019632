import { Address } from '@obo-dashboard/models/projectManagement.models';

export class User extends Address {
    public id?: number;
    public firstName: string;
    public lastName: string;
    public email: string;
    public NewPassword: string;
    public OldPassword: string;
    public CONFIRMPASSWORD: string;
    public title: string;
    public phoneNumber: string;
    public company: string;
    public password: string;
    public confirmPassword: string;
    public acceptTOS: string;
    public isContactable: boolean;
    public registrationDate: string;
    public latestSignInDate: string;
    public customerGroup: string;
    public emailConfirmed: boolean;

    public isProfileComplete(): boolean {
        return [this.firstName, this.lastName, this.customerGroup, this.company, this.title].every(
            (value) => value != null && value !== ''
        );
    }
}

export class PasswordPattern {
    public pattern: string;
}

export class LoginData {
    public username: string;
    public password: string;
}

export class ResetPasswordRequest {
    public password: string;
    public passwordConfirmed: string;
    public id: string;
    public token: string;
}
