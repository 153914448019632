import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ConfirmModalQuestionDirective } from '@obo-common/shared/components/confirmModal/confirmModalQuestion.directive';
import { ModalService } from '@obo-main/services/modal/modal.service';

@Component({
    selector: 'shd-confirm-modal',
    templateUrl: './confirmModal.component.html',
    styleUrls: ['./confirmModal.component.scss'],
    exportAs: 'confirmModal'
})
export class ConfirmModalComponent {
    @Output()
    public confirmed = new EventEmitter<void>();
    @Output()
    public dismissed = new EventEmitter<void>();

    @Input()
    public title: string;
    @Input()
    public question: string;

    @ViewChild('confirmModal') modal: TemplateRef<any>;
    @ContentChild(ConfirmModalQuestionDirective)
    questionTpl: ConfirmModalQuestionDirective;

    constructor(private modalService: ModalService, private viewContainerRef: ViewContainerRef) {}

    public show(): void {
        this.modalService.open(this.modal, { viewContainerRef: this.viewContainerRef }).result.then(
            (result) => this.confirmed.emit(),
            (reason) => this.dismissed.emit()
        );
    }
}
