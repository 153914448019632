@if (active) {
    <div class="loading-layer container-fluid d-flex align-items-center justify-content-center h-100">
        <div class="d-flex flex-column align-items-center">
            <div id="spinner" class="spinner" [class.spinner-progress]="progress">
                <div class="inner">
                    @if (progress !== undefined) {
                        <div class="progress-text">
                            {{ progress | number : '1.0-0' }} %
                        </div>
                    }
                </div>
                <p class="spinner-text">{{ spinnerText }}</p>
            </div>
        </div>
    </div>
}
