import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RequestPasswordService } from '@obo-account/requestPassword/requestPassword.service';
import { AlertService } from '@obo-main/services/common/alert/alert.service';
import { SpinnerService } from '@obo-main/services/common/spinner/spinner.service';
import { SEOService } from '@obo-main/services/seo/seo.service';
import { StateService } from '@uirouter/core';

@Component({
    selector: 'app-register-waiting',
    templateUrl: './registerWaiting.component.html'
})
export class RegisterWaitingComponent implements OnInit {
    @Input()
    public email: string = '';
    constructor(
        private seo: SEOService,
        private translateService: TranslateService,
        private requestPasswordService: RequestPasswordService,
        private spinnerService: SpinnerService,
        private alertService: AlertService,
        private stateService: StateService
    ) {}

    public ngOnInit(): void {
        this.seo.updateTitle(this.translateService.instant('REGISTER_WAITING_META_TITLE'));
    }

    public requestConfirmationMail(): void {
        this.spinnerService.startSpinner(this.translateService.instant('LOADING'));
        this.requestPasswordService.requestActivationMail(this.email).subscribe({
            next: () => {
                this.alertService.success(this.translateService.instant('LOGINCTRL_RECEIVE_CONFIRMATION_EMAIL_AGAIN'));
                this.spinnerService.stopSpinner();
                this.stateService.go('login');
            },
            error: () => {
                this.alertService.danger(this.translateService.instant('LOGINCTRL_FORGOTCONFIRMFAILURE'));
                this.spinnerService.stopSpinner();
            }
        });
    }
}
