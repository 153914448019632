import { Injectable } from '@angular/core';
import { Subject, timer } from 'rxjs';

@Injectable()
export class SpinnerService {
    private _totalUnitCount: number = 0;
    private _completedUnitCount: number = 0;

    public onSpinnerStatusChange: Subject<{
        enable: boolean;
        text: string | undefined;
    }> = new Subject();
    public onProgressUpdate: Subject<number> = new Subject<number>();

    /**
     * starts the spinner.
     * @param text Text to show below the spinner, default none
     * @param disposeAfter time in seconds, after which the spinner shall disappear, default infinite
     */
    public startSpinner(text?: string, disposeAfter?: number): void {
        this.onSpinnerStatusChange.next({ enable: true, text: text });
        if (disposeAfter) {
            timer(disposeAfter * 1000).subscribe(() => {
                // creates a timer
                this.onSpinnerStatusChange.next({
                    enable: false,
                    text: undefined
                }); // disposes the newly created alert after timer ellapsed
            });
        }
    }

    /**
     * Sets the percentage to be displayed on the spinner
     * @param perc
     */
    public setPercentage(perc: number): void {
        this.onProgressUpdate.next(perc);
    }

    public setTotalUnitCount(totalUnitCount: number): void {
        this._totalUnitCount = totalUnitCount;
        this._completedUnitCount = 0;
    }

    public addProgress(): void {
        this._completedUnitCount++;
        const progressPercentage = (this._completedUnitCount / this._totalUnitCount) * 100;
        this.onProgressUpdate.next(progressPercentage);
    }

    /**
     * stops the spinner
     */
    public stopSpinner(): void {
        this.resetProgress();
        this.onSpinnerStatusChange.next({ enable: false, text: undefined });
    }

    private resetProgress(): void {
        this._totalUnitCount = 0;
        this._completedUnitCount = 0;
    }
}
