import { AfterViewInit, Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { PortalCountryModalComponent } from './portalCountryModal/portalCountryModal.component';
import { Constants } from 'app/constants';
import { Subject } from 'rxjs';
import { DataGridExcelExportEvent, DataGridState } from '@obo-admin/dataGrid/models/dataGrid.models';
import { DataGridComponent } from '@obo-admin/dataGrid/dataGrid.component';
import { DataGridService } from '@obo-admin/dataGrid/services/dataGrid.service';
import { DataGridServiceFactory } from '@obo-admin/factories/dataGridService.factory';

@Component({
    selector: 'adm-router-portal-management',
    templateUrl: './portalManagement.component.html'
})
export class PortalManagementComponent implements OnDestroy, AfterViewInit {
    public selectedRow?: any;
    @ViewChild('grid')
    public grid: DataGridComponent;
    public dataGridState: DataGridState = {
        filterState: [],
        sortingState: [],
        paginationState: {
            top: 10,
            skip: 0
        }
    };
    public formGroup: UntypedFormGroup;

    @ViewChild('editPortalCountryModal')
    public editPortalModal: PortalCountryModalComponent;

    public dataGridService: DataGridService;

    protected onDestroy = new Subject();

    constructor(
        @Inject('APIPREFIX') private apiPrefix: string,
        private translateService: TranslateService,
        private dataGridServiceFactory: DataGridServiceFactory
    ) {
        this.dataGridService = this.dataGridServiceFactory.getService(`${this.apiPrefix}Portal/Administration/Regions`);
    }

    public saveHandler(): void {
        const value = this.formGroup!.value;
        if (value.id) {
            this.dataGridService.update(value);
        } else {
            this.dataGridService.create(value);
        }

        this.editPortalModal.close();
        this.selectedRow = undefined;
    }

    public selectRow(event: any): void {
        this.selectedRow = event;
        this.editHandler({ dataItem: this.selectedRow });
        this.editPortalModal.show();
    }

    public editHandler({ dataItem }: any) {
        this.formGroup = this.createFormGroup(dataItem);
    }

    public cancelHandler() {
        this.selectedRow = undefined;
        this.formGroup = undefined;
    }

    public createFormGroup(item: Region): UntypedFormGroup {
        return new UntypedFormGroup({
            id: new UntypedFormControl(item.id),
            name: new UntypedFormControl(item.name),
            displayName: new UntypedFormControl(item.displayName),
            features: new UntypedFormArray(
                item.features.map((f: Feature) => {
                    const fg = new UntypedFormGroup({
                        id: new UntypedFormControl(f.id),
                        url: new UntypedFormControl({
                            value: f.url && f.url.length > 0 ? f.url : null,
                            disabled: !f.isActive
                        }),
                        isActive: new UntypedFormControl(f.isActive),
                        name: new UntypedFormControl(f.name)
                    });
                    fg.controls.isActive.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((isActive) => {
                        if (isActive) {
                            fg.controls.url?.enable();
                            fg.controls.teaserUrl?.enable();
                            fg.controls.webinarUrl?.enable();
                        } else {
                            fg.controls.url?.disable();
                            fg.controls.teaserUrl?.disable();
                            fg.controls.webinarUrl?.disable();
                        }
                    });
                    return fg;
                })
            )
        });
    }

    public onExcelExport(e: DataGridExcelExportEvent): void {
        this.dataGridService.getAllEntries().subscribe((result) => {
            const rows = [];
            const headerCells = [];
            headerCells.push(this.translateService.instant('LANGUAGES_DISPLAYNAME'));
            result.items[0].features.forEach((feature: any) => headerCells.push(feature.name));
            rows.push(headerCells);
            result.items.forEach((region: any) => {
                const cells = [];
                cells.push(region.displayName);
                region.features.forEach((feature: any) =>
                    cells.push(feature.isActive ? Constants.Symbols.booleanTrue : Constants.Symbols.booleanFalse)
                );
                rows.push(cells);
            });
            e.updatedRows.next(rows);
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next(1);
        this.onDestroy.complete();
    }

    ngAfterViewInit(): void {
        this.grid.dataGridStateChange
            .pipe(
                debounceTime(200),
                distinctUntilChanged(),
                tap((state) => (this.dataGridState = state))
            )
            .subscribe((state) => this.dataGridService.read(state));
    }
}

interface Region {
    id: number;
    name: string;
    displayName: string;
    features: Feature[];
}

interface Feature {
    id: number;
    url: string;
    isActive: boolean;
    name: string;
}
