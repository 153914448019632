import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerService } from '@obo-main/services/common/spinner/spinner.service';
import { AlertService } from '@obo-main/services/common/alert/alert.service';
import { finalize, tap } from 'rxjs/operators';
import {
    DataGridFilterItem,
    DataGridFilterOperator,
    DataGridPaginationState,
    DataGridResult,
    DataGridState
} from '@obo-admin/dataGrid/models/dataGrid.models';

const itemIndex = (item: any, data: any[]): number => {
    if ('id' in item) {
        return data.findIndex((d) => d.id === item.id);
    } else if ('key' in item) {
        return data.findIndex((d) => d.key === item.key);
    }
    return data.indexOf(item);
};

@Injectable()
export class DataGridService extends BehaviorSubject<DataGridResult> {
    private gridState: DataGridState;
    private url: string;
    public data: DataGridResult;

    constructor(
        private http: HttpClient,
        private spinnerService: SpinnerService,
        private alertService: AlertService,
        private translateService: TranslateService
    ) {
        super({ items: [], count: 0 });
    }

    /**
     * initializes the datasource
     * @param url
     */
    public initialize(url: string): void {
        this.reset();
        this.url = url;
    }

    /**
     * ready items from datasource
     */
    public read(state: DataGridState): void {
        this.gridState = state;
        this.getEntries(state)
            .pipe(tap((res) => (this.data = res)))
            .subscribe((res) => {
                super.next(res);
            });
    }

    public create(item: any): void {
        this.spinnerService.startSpinner();
        this.http
            .post<DataGridResult>(`${this.url}`, item)
            .pipe(finalize(() => this.spinnerService.stopSpinner()))
            .subscribe({
                next: (res) => {
                    let data = Object.assign({}, this.data);
                    data.items.unshift(res.items[0]);
                    this.data = data;
                    super.next(this.data);
                    this.alertService.success(this.translateService.instant('ADMIN_CREATE_SUCCESSFULL'));
                },
                error: (error) => this.alertService.warning(JSON.stringify(error))
            });
    }

    /**
     * updates an item from datasource
     * @param item
     */
    public update(item: any): void {
        this.spinnerService.startSpinner();
        let index = itemIndex(item, this.data.items);
        this.http
            .put<DataGridResult>(`${this.url}/${item.id}`, item)
            .pipe(finalize(() => this.spinnerService.stopSpinner()))
            .subscribe({
                next: () => {
                    let data = Object.assign({}, this.data);
                    data.items.splice(index, 1, item);
                    this.data = data;
                    super.next(this.data);
                    this.alertService.success(this.translateService.instant('ADMIN_UPDATE_SUCCESSFULL'));
                },
                error: (error) => this.alertService.warning(JSON.stringify(error))
            });
    }

    /**
     * removes an item from datasource
     * @param item
     */
    public remove(item: any): Observable<any> {
        this.spinnerService.startSpinner();
        const returnSubject = new Subject();
        let index = itemIndex(item, this.data.items);
        this.http
            .delete<DataGridResult>(`${this.url}`, item)
            .pipe(finalize(() => this.spinnerService.stopSpinner()))
            .subscribe({
                next: (res) => {
                    let data = Object.assign({}, this.data);
                    data.items.splice(index, 1);
                    this.data = data;
                    super.next(this.data);
                    this.alertService.success(this.translateService.instant('ADMIN_DELETE_SUCCESSFULL'));
                    returnSubject.next(true);
                    returnSubject.complete();
                },
                error: (error) => {
                    this.alertService.warning(JSON.stringify(error));
                    returnSubject.error(error);
                }
            });
        return returnSubject;
    }

    public getAllEntries(): Observable<DataGridResult> {
        const gridStateCopy = new DataGridState();
        gridStateCopy.paginationState = new DataGridPaginationState();
        gridStateCopy.paginationState.top = undefined;
        gridStateCopy.paginationState.skip = undefined;
        gridStateCopy.filterState = this.gridState.filterState;
        gridStateCopy.sortingState = this.gridState.sortingState;
        return this.getEntries(gridStateCopy);
    }

    /**
     * clears the datasource
     */
    private reset() {
        this.data = { items: [], count: 0 };
    }

    private toQueryOptions(gridState: DataGridState): HttpParams {
        let httpParams = new HttpParams();
        if (gridState?.paginationState) {
            httpParams = httpParams.set('$skip', gridState.paginationState.skip);
            httpParams = httpParams.set('$top', gridState.paginationState.top);
        }

        if (gridState?.sortingState.length > 0) {
            const sortQuery = gridState.sortingState.map((sort) => `${sort.id} ${sort.desc ? 'desc' : 'asc'}`).join(',');
            httpParams = httpParams.set('$orderby', sortQuery);
        }

        if (gridState?.filterState.length > 0) {
            const query = gridState.filterState
                .map((filter) => {
                    let filterStr = filter.filters.map((f) => this.getODataFilterNotation(f)).join(` ${filter.logic} `);
                    if (filter.logic === 'or') {
                        filterStr = `(${filterStr})`;
                    }
                    return filterStr;
                })
                .join(' and ');

            httpParams = httpParams.set('$filter', query);
        }
        return httpParams;
    }

    private getEntries(state: DataGridState): Observable<DataGridResult> {
        this.spinnerService.startSpinner();
        const queryOptions = this.toQueryOptions(state);
        return this.http
            .get<DataGridResult>(`${this.url}`, { params: queryOptions })
            .pipe(finalize(() => this.spinnerService.stopSpinner()));
    }

    private getODataFilterNotation(filter: DataGridFilterItem): string {
        const filterValue =
            typeof filter.value === 'number' || typeof filter.value === 'boolean' ? `${filter.value}` : `'${filter.value}'`;
        switch (filter.operator) {
            case DataGridFilterOperator.Contains: {
                return `substringof('${filter.value}',${filter.id})`;
            }
            case DataGridFilterOperator.StartsWith: {
                return `startswith('${filter.value}',${filter.id})`;
            }
            case DataGridFilterOperator.DoesNotContain: {
                return `substringof('${filter.value}', ${filter.id}) eq false`;
            }
            case DataGridFilterOperator.IsNull: {
                return `${filter.id} eq null`;
            }
            case DataGridFilterOperator.NotNull: {
                return `${filter.id} ne null`;
            }
            case DataGridFilterOperator.IsEqualTo: {
                return `${filter.id} eq ${filterValue}`;
            }
            case DataGridFilterOperator.NotEqualTo: {
                return `${filter.id} ne ${filterValue}`;
            }
            case DataGridFilterOperator.EndsWith: {
                return `endswith('${filter.value}', ${filter.id})`;
            }
            case DataGridFilterOperator.GreaterThanOrEqualTo: {
                return `${filter.id} ge ${filter.value}`;
            }
            case DataGridFilterOperator.LessThanOrEqualTo: {
                return `${filter.id} le ${filter.value}`;
            }
        }
    }
}
