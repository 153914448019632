<section id="dashboard">
    <h1 class="mb-5">
        @if (user.firstName) {
        {{ today | greeting | translate | stringFormat : [user.firstName] }}
        } @else {
        {{ today | greeting : true | translate }}
        }
    </h1>
    <div class="dashboard-container">
        <h2>
            <fa-icon class="me-2" [icon]="['fal', 'screwdriver-wrench']"></fa-icon>
            {{ 'DASHBOARD_QUICK_CONFIGURATION' | translate }}
        </h2>
        <div class="quick-start">
            <shd-infinite-carousel
                #infiniteCarousel="infiniteCarousel"
                [cycleTime]="10000"
                gridClasses="col-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2">
                @for (module of plannableModules; track module.id) {
                <ng-template let-carousel shdInfiniteCarouselItem>
                    <app-quick-start-card
                        [module]="module"
                        (configuratorNonMobile)="openWarningModal()"
                        [isMobile]="isMobile"></app-quick-start-card>
                </ng-template>
                }
            </shd-infinite-carousel>
        </div>

        <div class="d-flex align-items-center justify-content-between flex-wrap">
            <h2>
                <fa-icon class="me-2" [icon]="['fal', 'folder']"></fa-icon>
                {{ 'PROJECTS_TITLE' | translate }}
            </h2>
            <div class="filter-search-container">
                <div class="project-filters">
                    @for (module of plannableModules; track module.id) {
                    <div
                        class="module-icon-container"
                        [ngClass]="{ active: activeFilters.includes(module.id) }"
                        (click)="updateFilters(module.id)">
                        <div class="module-icon">
                            <shd-module-icon
                                [moduleName]="module.name"
                                [color]="activeFilters.includes(module.id) ? 'primary-color' : 'obo-blue-dark'"></shd-module-icon>
                        </div>
                        <div class="module-name">
                            <span>
                                {{ 'BUILDING_MODULES_' + module.name | uppercase | translate }}
                            </span>
                        </div>
                    </div>
                    }
                </div>
                <div class="project-search">
                    <input
                        id="project-search"
                        class="form-control"
                        [attr.placeholder]="'SHARED_SEARCH_DOTS' | translate"
                        (input)="filterProjects($event.target.value)" />
                    <span class="icon">
                        <fa-icon [icon]="['fal', 'magnifying-glass']"></fa-icon>
                    </span>
                </div>
            </div>
        </div>
        <div class="projects">
            <div class="project-list">
                <div class="card-grow card-shadow add-project-card" (click)="addProject()">
                    <fa-icon [icon]="['fal', 'plus']" size="2x"></fa-icon>
                    {{ 'PROJECTS_ADDNEW' | translate }}
                </div>
                @for (project of showAllProjects ? filteredProjectList : (filteredProjectList | slice : 0 : 4); track project.id)
                {
                <app-project-card [project]="project" (projectChange)="reloadProjects()"></app-project-card>
                }
                <!-- pseudo elements to keep grid flow, if the project count is lower than 4 -->
                <div class="pseudo"></div>
                <div class="pseudo"></div>
                <div class="pseudo"></div>
                <div class="pseudo"></div>
            </div>
        </div>
        @if (filteredProjectList.length > 4) {
        <div class="mb-5">
            <button id="showMoreProjectsBtn" class="btn btn-link p-0" (click)="showAllProjects = !showAllProjects">
                <fa-icon [icon]="['fal', showAllProjects ? 'chevron-up' : 'chevron-down']"></fa-icon>
                {{ (showAllProjects ? 'BTN_SHOW_LESS' : 'BTN_SHOW_MORE') | translate }}
            </button>
        </div>
        }
    </div>
    <shd-desktop-only-modal #desktopOnlyModalTpl></shd-desktop-only-modal>
</section>
