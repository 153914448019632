<div class="toggle-container">
    @if(plannedPlannings.length > 0) {
    <button class="btn" (click)="configurationTab = 'planned'">
        <div class="mode-option" [class.active]="configurationTab === 'planned'">
            <h3 class="m-0">{{ 'BUILDING_MODULES_PLANNED' | translate }}</h3>
        </div>
    </button>
    } @if(unplannedPlannings.length > 0) {
    <button class="btn" (click)="configurationTab = 'unplanned'">
        <div class="mode-option" [class.active]="configurationTab === 'unplanned'">
            <h3 class="m-0">{{ 'BUILDING_MODULES_UNPLANNED' | translate }}</h3>
        </div>
    </button>
    }
</div>
<div class="plannings-grid">
    @if(configurationTab === 'planned' && plannedPlannings.length > 0) { @for (planning of plannedPlannings; track planning) {
    <app-planning-card
        [planning]="planning"
        [buildingId]="buildingId"
        [projectId]="projectId"
        [isMobile]="isMobile"
        (configuratorNonMobile)="openWarningModal()"
        (reloadPlannings)="reloadBuildings.emit(buildingId)"></app-planning-card>
    }
    <!-- pseudo elements to keep grid flow, if the planning count is lower than 3 -->
    <div class="pseudo"></div>
    <div class="pseudo"></div>
    } @else { @for (planning of unplannedPlannings; track planning) {
    <app-planning-card
        [planning]="planning"
        [buildingId]="buildingId"
        [projectId]="projectId"
        [isMobile]="isMobile"
        (configuratorNonMobile)="openWarningModal()"
        (openDetails)="openDetails($event)"></app-planning-card>
    }
    <!-- pseudo elements to keep grid flow, if the planning count is lower than 3 -->
    <div class="pseudo"></div>
    <div class="pseudo"></div>
    }
</div>

<app-configuration-detail-bar></app-configuration-detail-bar>

<shd-desktop-only-modal #desktopOnlyModalTpl></shd-desktop-only-modal>
