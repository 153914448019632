import { Injectable, signal } from '@angular/core';
import { DataGridFilter, DataGridFilterState } from '@obo-admin/dataGrid/models/dataGrid.models';
import { Subject } from 'rxjs';

@Injectable()
export class DataGridFilterService {
    public filterChanged: Subject<string> = new Subject();
    readonly filterStateSignal = signal<DataGridFilterState>([]);
    private _filterState: DataGridFilterState = [];

    addFilter(filter: DataGridFilter): void {
        let filterToChange = this._filterState.find((f) => f.filters[0].id === filter.filters[0].id);
        if (filterToChange) {
            let indexOfFilterToChange = this._filterState.indexOf(filterToChange);
            this._filterState.splice(indexOfFilterToChange, 1);
        }
        this._filterState.push(filter);
        this.filterStateSignal.set([...this._filterState]);
        this.filterChanged.next(filter.filters[0].id);
    }

    removeFilter(field: string): void {
        const filterToRemove = this._filterState.find((f) => f.filters.map((x) => x.id).includes(field));
        if (filterToRemove) {
            const indexToRemove = this._filterState.indexOf(filterToRemove);
            this._filterState.splice(indexToRemove, 1);
            this.filterStateSignal.set([...this._filterState]);
        }
        this.filterChanged.next(field);
    }

    isFilterActive(field: string): boolean {
        return this.filterStateSignal().some((filter) => filter.filters.some((x) => x.id === field));
    }

    set filterState(filterState: DataGridFilterState) {
        this._filterState = filterState;
    }
}
