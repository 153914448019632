import { AfterViewInit, Component, Inject, OnDestroy, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ProductModel } from '@obo-admin/admin.models';
import { concatAll, concatMap, debounceTime, distinctUntilChanged, finalize, map, take, takeUntil, tap } from 'rxjs/operators';
import { OverlayConfig } from '@angular/cdk/overlay';
import { OVERLAY_FULL_SCREEN_CONFIG } from '@obo-main/injectionTokens/overlay.tokens';
import { DataGridComponent } from '@obo-admin/dataGrid/dataGrid.component';
import { DataGridState } from '@obo-admin/dataGrid/models/dataGrid.models';
import { DataGridService } from '@obo-admin/dataGrid/services/dataGrid.service';
import { DataGridServiceFactory } from '@obo-admin/factories/dataGridService.factory';
import { ModalService } from '@obo-main/services/modal/modal.service';
import { ModalRef } from '@obo-main/services/modal/modalRef.model';
import { AlertService } from '@obo-main/services/common/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { CacheService } from '@obo-common/shared/services/cache.service';
import { Subject } from 'rxjs';

@Component({
    selector: 'adm-router-database-lookup',
    templateUrl: './databaseLookup.component.html',
    styleUrls: ['./databaseLookup.component.scss']
})
export class DatabaseLookupComponent implements AfterViewInit, OnDestroy {
    public selectedRow?: ProductModel;
    @ViewChild('grid')
    public grid: DataGridComponent;
    public dataGridState: DataGridState = {
        filterState: [],
        sortingState: [],
        paginationState: {
            top: 10,
            skip: 0
        }
    };
    public dataGridService: DataGridService;

    @ViewChild('modal')
    public modal: TemplateRef<any>;
    public modalRef: ModalRef;
    public disabled: boolean = false;
    private onDestroy = new Subject();

    constructor(
        @Inject('APIPREFIX') private apiPrefix: string,
        private modalService: ModalService,
        @Inject(OVERLAY_FULL_SCREEN_CONFIG)
        private overlayConfig: OverlayConfig,
        private dataGridServiceFactory: DataGridServiceFactory,
        private viewContainerRef: ViewContainerRef,
        private alertService: AlertService,
        private translateService: TranslateService,
        private cacheService: CacheService
    ) {
        this.dataGridService = this.dataGridServiceFactory.getService(`${this.apiPrefix}Administration/Products`);
    }

    public selectRow(row: any): void {
        this.selectedRow = row;
        this.modalRef = this.modalService.open(this.modal, {
            config: this.overlayConfig,
            viewContainerRef: this.viewContainerRef
        });
    }

    public deleteRow(row: ProductModel): void {
        this.dataGridService.remove(row).subscribe(() => {
            this.selectedRow = undefined;
        });
    }

    public removePage(): void {
        this.dataGridService
            .pipe(
                take(1),
                map((data) => data.items),
                concatAll(),
                concatMap((row) => this.dataGridService.remove(row)),
                finalize(() => this.dataGridService.read(this.dataGridState))
            )
            .subscribe();
    }

    public delete(): void {
        this.selectedRow ? this.deleteRow(this.selectedRow) : this.removePage();
        this.modalRef.close();
    }

    public ngAfterViewInit(): void {
        this.grid.dataGridStateChange
            .pipe(
                debounceTime(700),
                distinctUntilChanged(),
                tap((state) => (this.dataGridState = state))
            )
            .subscribe((state) => this.dataGridService.read(state));
        this.dataGridService.read(this.dataGridState);
    }

    public ngOnDestroy(): void {
        this.onDestroy.next(1);
        this.onDestroy.complete();
    }

    public clearCache() {
        this.cacheService
            .clearResourceCache()
            .pipe(takeUntil(this.onDestroy))
            .subscribe({
                next: () => {
                    this.alertService.success(this.translateService.instant('ADMIN_CACHE_CLEAR_SUCCESS'));
                },
                error: () => {
                    this.alertService.danger(this.translateService.instant('ADMIN_CACHE_CLEAR_FAILURE'));
                }
            });
        this.disabled = true;
        setTimeout(() => (this.disabled = false), 3000);
    }
}
