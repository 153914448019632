import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { SpinnerService } from '@obo-main/services/common/spinner/spinner.service';
import { Logger } from '@obo-main/utils/logger/logger.service';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html'
})
export class SpinnerComponent implements OnInit {
    public active: boolean = true;
    public spinnerText: string = '';
    public progress: number | undefined;

    constructor(@Inject(PLATFORM_ID) private platformId: any, private logger: Logger, private spinnerService: SpinnerService) {}

    public ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.active = false;
            this.logger.debug('removing initial spinner because the platform is Browser');
        }
        this.spinnerService.onSpinnerStatusChange.pipe(debounceTime(100)).subscribe((event) => {
            this.logger.debug(`starting spinner with text: ${event.text}`);
            this.active = event.enable;
            this.spinnerText = event.text || '';
        });
        this.spinnerService.onProgressUpdate.subscribe((percent) => {
            this.progress = percent;
            if (percent === 0 || percent >= 98) {
                this.progress = undefined;
            }
        });
    }
}
