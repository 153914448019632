import { OverlayConfig } from '@angular/cdk/overlay';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Region } from '@obo-main/services/region/region.models';
import { AppSettings } from '@obo-main/utils/appSettings.service';
import { LocalStorage } from '@obo-main/utils/localstorage.service';
import { PortalRegion } from '@obo-portal/models/portal.models';
import { Constants } from 'app/constants';
import { Observable, of, Subject } from 'rxjs';
import { catchError, concatAll, first, mergeMap, tap } from 'rxjs/operators';
import { Language } from '../language/language.models';
import { LanguageService } from '../language/language.service';
import { OVERLAY_REGION_LANGUAGE_SELECTION_CONFIG } from '@obo-main/injectionTokens/overlay.tokens';
import { RegionLanguageSelectorModalComponent } from '@obo-main/components/regionLanguageSelectorModal/regionLanguageSelectorModal.component';
import { ModalService } from '@obo-main/services/modal/modal.service';

@Injectable()
export class RegionService {
    public selectedApplicationRegion: Region | undefined;
    private regionCache: Array<Region> | undefined;
    public portalRegions: PortalRegion[];
    public selectedPortalRegion: PortalRegion;
    public onPortalRegionChange: Subject<PortalRegion> = new Subject<PortalRegion>();

    constructor(
        private appSettings: AppSettings,
        private translateService: TranslateService,
        @Inject(LocalStorage) private localStorage: Storage,
        private languageService: LanguageService,
        private http: HttpClient,
        @Inject(OVERLAY_REGION_LANGUAGE_SELECTION_CONFIG)
        private overlayConfig: OverlayConfig,
        private modalService: ModalService
    ) {}

    public getRegions(): Observable<Region[]> {
        if (this.regionCache != null) {
            return of(this.regionCache!);
        } else {
            return this.http
                .get<Region[]>(`${this.appSettings.getItem('settings.apiPrefix')}Regions`)
                .pipe(tap((regions) => (this.regionCache = regions)));
        }
    }

    public findRegionById(regionId: number): Observable<Region> {
        return this.getRegions().pipe(
            concatAll(),
            first((region) => region.id === regionId)
        );
    }

    private updateApplicationRegion(regionId: number) {
        this.findRegionById(regionId).subscribe((applicationRegion) => (this.selectedApplicationRegion = applicationRegion));
    }

    public setPortalRegion(region: PortalRegion): void {
        this.localStorage.setItem(Constants.storageTokens.portal_region, region.name);
        this.selectedPortalRegion = region;
        this.updateApplicationRegion(region.id);
        this.onPortalRegionChange.next(region);
    }

    public initialize(): Promise<any> {
        const apiPrefix = this.appSettings.getItem('settings.apiPrefix');
        return this.http
            .get<PortalRegion[]>(`${apiPrefix}Portal/Regions`)
            .pipe(
                tap((regions) => {
                    this.portalRegions = regions;
                }),
                mergeMap((regions) => {
                    const portalRegionFromLocalStorage = this.localStorage.getItem(Constants.storageTokens.portal_region);
                    if (portalRegionFromLocalStorage) {
                        const region = this.portalRegions.find((p) => p.name === portalRegionFromLocalStorage);
                        if (region) {
                            return of(region);
                        }
                    }
                    return this.http.get<{ isoCode: string }>(`${apiPrefix}GeoLocations/Region`).pipe(
                        catchError(() => of(undefined)),
                        mergeMap((location) => {
                            const userRegionFound = location
                                ? regions.find((r) => location.isoCode.localeCompare(r.name))
                                : undefined;

                            return new Promise<PortalRegion>((resolve) => {
                                return this.languageService.getAvailableLanguagesForModuleById(2).subscribe((languages) => {
                                    this.openRegionLanguageSelectorModal(
                                        this.portalRegions,
                                        userRegionFound,
                                        (region: PortalRegion) => {
                                            this.setPortalRegion(region);
                                            resolve(region);
                                        },
                                        languages.find((l) => l.name === this.translateService.currentLang),
                                        languages
                                    );
                                });
                            });
                        })
                    );
                })
            )
            .pipe(
                tap((region) => {
                    this.setPortalRegion(region);
                })
            )
            .toPromise();
    }

    public openRegionLanguageSelectorModal(
        portalRegions: Array<PortalRegion>,
        selectedRegion: PortalRegion,
        saveRegionFn: (region: PortalRegion) => void,
        selectedLanguage: Language,
        languages: Array<Language>,
        isDismissible?: boolean
    ): void {
        this.modalService.open(RegionLanguageSelectorModalComponent, {
            config: this.overlayConfig,
            isBackdropClickable: isDismissible,
            injector: Injector.create({
                providers: [
                    {
                        provide: 'portalRegions',
                        useValue: portalRegions
                    },
                    {
                        provide: 'selectedRegion',
                        useValue: selectedRegion
                    },
                    {
                        provide: 'saveRegionFn',
                        useValue: (region: PortalRegion) => {
                            saveRegionFn(region);
                        }
                    },
                    {
                        provide: 'languages',
                        useValue: languages
                    },
                    {
                        provide: 'selectedLanguage',
                        useValue: selectedLanguage
                    },
                    {
                        provide: 'saveLanguageFn',
                        useValue: (language: Language) => {
                            this.languageService.setLanguage(language);
                        }
                    },
                    {
                        provide: 'isDismissible',
                        useValue: isDismissible
                    }
                ]
            })
        });
    }

    public isElbridgeEnabled(): boolean {
        return !!this.selectedApplicationRegion?.isElbridgeEnabled;
    }
}
