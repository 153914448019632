<ng-template [ngTemplateOutlet]="template" [ngTemplateOutletContext]="context"></ng-template>
<ng-template let-formControl="formControl" let-value="value" let-openFn="openEditModeFn" #standardRead>
    <div class="read-mode mt-1 d-flex align-items-center justify-content-between w-100" shdInlineEditButton="edit">
        <span class="input-text">{{ value }}</span>
        <button [title]="'BTN_EDIT' | translate" class="btn btn-fa btn-color-orange inline-btn-edit">
            <fa-icon [icon]="['fal', 'pencil']"></fa-icon>
        </button>
    </div>
</ng-template>
<ng-template #standardWrite>
    <form class="w-100" [formGroup]="formGroup" (ngSubmit)="updateValue()" (keydown.escape)="reset()">
        <div class="edit-mode input-group mt-1">
            <input
                shdInlineEditInput
                class="form-control inline-input h-100"
                type="text"
                formControlName="formControl"
                shdAutoFocus
                [andSelect]="true"
                shdValidateNaming/>
            <div class="input-group-append pe-1 d-flex align-items-center">
                <button shdInlineEditButton="save" [disabled]="formGroup.invalid" [title]="'BTN_SAVE' | translate"
                        class="btn btn-fa btn-color-orange ms-0">
                    <fa-icon [icon]="['fal', 'check']"></fa-icon>
                </button>
                <button shdInlineEditButton="discard" [title]="'BTN_ABORT' | translate"
                        class="btn btn-fa btn-color-orange me-0">
                    <fa-icon [icon]="['fal', 'times']"></fa-icon>
                </button>
            </div>
        </div>
        <shd-error-field [control]="formGroup.controls['formControl']"></shd-error-field>
    </form>
</ng-template>
