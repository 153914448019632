<form name="requestPasswordForm" id="login-form" (ngSubmit)="request()" role="form" #requestPasswordForm="ngForm">
    <div>
        <div class="mb-3 required">
            <label class="form-label" for="email">
                {{ 'LOGINFORM_EMAIL' | translate }}
            </label>
            <input
                [(ngModel)]="email"
                type="email"
                name="email"
                id="email"
                tabindex="0"
                class="form-control form-control-border"
                required
                shdValidateInput
                shdValidateEmail/>
        </div>
    </div>
    <shd-recaptcha [(valid)]="reCaptchaValid"></shd-recaptcha>
    <small class="form-text text-muted">
        {{ 'SHARED_REQUIRED_FIELD_INFO' | translate }}
    </small>
    <div class="mb-3">
        <button
            class="btn btn-success"
            [disabled]="requestPasswordForm.invalid || !reCaptchaValid"
            type="submit"
            name="submit"
            id="submit"
            tabindex="0">
            {{ 'LOGINFORM_BTN_REQUEST' | translate }}
        </button>
    </div>
</form>
