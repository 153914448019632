import { Component, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ModalService } from '@obo-main/services/modal/modal.service';

@Component({
    selector: 'shd-desktop-only-modal',
    templateUrl: './desktopOnlyModal.component.html',
    exportAs: 'DesktopOnlyModalComponent'
})
export class DesktopOnlyModalComponent {
    @ViewChild('desktopOnlyModal') modal: TemplateRef<any>;

    constructor(private modalService: ModalService, private viewContainerRef: ViewContainerRef) {}

    public open(): void {
        this.modalService.open(this.modal, { viewContainerRef: this.viewContainerRef });
    }
}
