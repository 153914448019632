import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AddressService } from '@obo-common/shared/services/address.service';
import { Address } from '@obo-dashboard/models/projectManagement.models';
import { Region } from '@obo-main/services/region/region.models';
import { RegionService } from '@obo-main/services/region/region.service';
import { PortalRegion } from '@obo-portal/models/portal.models';
import { debounceTime, Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'shd-address-validation',
    templateUrl: './addressValidation.component.html'
})
export class AddressValidationComponent {
    @Input()
    public address: Address;
    @Input()
    public disabled: boolean = false;
    @Input()
    public required: boolean = false;
    @Output()
    public addressFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output()
    public addressChanged: EventEmitter<Address> = new EventEmitter<Address>();
    @Output()
    public selectedRegionChanged: EventEmitter<PortalRegion | Region> = new EventEmitter<PortalRegion | Region>();

    @ViewChild('addressForm')
    public addressForm: NgForm;

    public missingAddressData: {} = undefined;
    public addressValidated: boolean = false;
    public selectedRegion: PortalRegion | Region;

    private onDestroy = new Subject();

    constructor(
        private translateService: TranslateService,
        private addressService: AddressService,
        private regionService: RegionService
    ) {}

    public ngOnInit(): void {
        this.setPlanningRegionById();
        if (this.address.city) {
            this.addressChanged.emit(this.address);
        }
    }

    public ngOnDestroy(): void {
        this.onDestroy.next(1);
        this.onDestroy.complete();
    }

    public ngAfterViewInit(): void {
        if (!this.disabled) {
            this.addressForm.form.valueChanges.pipe(debounceTime(100)).subscribe(() => {
                this.addressFormValid.emit(this.isValid());
            });
            this.onCompleteAddress();
        } else {
            this.addressFormValid.emit(this.isValid());
        }
    }

    public patchPlaceResult(event) {
        if (event) {
            const address = AddressService.convertPlaceResultToAddress(event);
            this.updateAddress(address);
        }
    }

    public updateAddress(address: Address): void {
        this.address = new Address();
        this.address.regionId = address?.regionId ?? this.selectedRegion.id;
        this.address.city = address?.city;
        this.address.postalCode = address?.postalCode;
        this.address.houseNumber = address?.houseNumber;
        this.address.street = address?.street;
        this.address.latitude = address?.latitude;
        this.address.longitude = address?.longitude;

        this.getMissing();
        this.addressService.initAddressSubject(this.address);
    }

    private getMissing(): void {
        const allFieldsPresent = this.address.street && this.address.houseNumber && this.address.postalCode && this.address.city;
        if (!allFieldsPresent) {
            this.missingAddressData = {
                street: !this.address.street ? this.translateService.instant('SHARED_STREET') + ', ' : '',
                houseNumber: !this.address.houseNumber ? this.translateService.instant('SHARED_HOUSENUMBER') + ', ' : '',
                postalCode: !this.address.postalCode ? this.translateService.instant('SHARED_ZIPCODE') + ', ' : '',
                city: !this.address.city ? this.translateService.instant('SHARED_CITY') + ', ' : ''
            };
        } else {
            this.onCompleteAddress();
        }
    }

    private onCompleteAddress(): void {
        this.missingAddressData = undefined;
        this.addressValidated = true;
        this.addressFormValid.emit(this.isValid());
        this.addressChanged.emit(this.address);
    }

    public setPlanningRegionById(): void {
        this.address.regionId
            ? this.regionService
                  .findRegionById(this.address.regionId)
                  .pipe(takeUntil(this.onDestroy))
                  .subscribe((region) => (this.selectedRegion = region))
            : (this.selectedRegion = this.regionService.selectedPortalRegion);
        this.address.regionId = this.selectedRegion.id;
        this.selectedRegionChanged.emit(this.selectedRegion);
    }

    public onAddressChange(value: string): void {
        this.addressValidated = value.length === 0;
    }

    private isValid(): boolean {
        return this.disabled ? this.addressForm.valid : this.addressForm?.valid && this.addressValidated;
    }
}
