import { Component, Inject } from '@angular/core';
import { Address, Building } from '@obo-dashboard/models/projectManagement.models';
import { Region } from '@obo-main/services/region/region.models';
import { Observable } from 'rxjs';
import { ActiveModal } from '@obo-main/services/modal/activeModal.model';

@Component({
    selector: 'app-building-edit-modal',
    template: `
        <div class="modal">
            <div class="modal-header d-flex align-items-center justify-content-between">
                <h3 class="m-0">
                    {{ title }}
                </h3>

                <button class="btn btn-fa text-black" type="button" (click)="activeModal.dismiss()">
                    <fa-icon [icon]="['fal', 'times']"></fa-icon>
                </button>
            </div>
            <form #buildingEditForm="ngForm" (ngSubmit)="activeModal.close(); saveFn(building)">
                <div class="modal-body">
                    <div class="mb-3 required">
                        <label class="form-label control-label" for="name">{{ 'BUILDING_NAME' | translate }}</label>
                        <input
                            class="form-control form-control-border"
                            [(ngModel)]="building.name"
                            id="name"
                            name="name"
                            type="text"
                            required
                            shdValidateInput
                            shdValidateNaming />
                    </div>
                    @if (building.regionId) {
                    <div class="mb-3 d-none d-lg-block">
                        <shd-address-validation
                            (addressChanged)="updateBuilding($event)"
                            (addressFormValid)="addressValidated = $event"
                            [address]="building"></shd-address-validation>
                    </div>
                    }
                    <div class="mb-3 required">
                        <label class="form-label control-label" for="region">
                            {{ 'SHARED_REGION' | translate }}
                            <shd-info-button
                                [text]="
                                    (regionIdReadonly ? 'MAIN_PROJECT_REGION_READONLY' : 'MAIN_PROJECT_REGION_INFO') | translate
                                "
                                placement="top"></shd-info-button>
                        </label>
                        <select
                            [(ngModel)]="building.regionId"
                            name="region"
                            id="region"
                            class="form-control form-control-border"
                            [disabled]="regionIdReadonly"
                            required>
                            @for (region of regions | async; track region) {
                            <option [ngValue]="region?.id">
                                <span class="flag-icon flag-icon-{{ region?.name | lowercase }} me-2"></span>
                                {{ region?.displayName | translate }}
                            </option>
                            }
                        </select>
                    </div>
                </div>

                <div class="modal-footer btn-container d-flex justify-content-end align-items-stretch">
                    <button class="btn btn-secondary me-2" type="button" (click)="activeModal.dismiss()">
                        {{ 'BTN_ABORT' | translate }}
                    </button>
                    <button
                        class="btn btn-success mh-100"
                        type="submit"
                        [disabled]="buildingEditForm.invalid || !addressValidated">
                        {{ 'BTN_SAVE' | translate }}
                    </button>
                </div>
            </form>
        </div>
    `
})
export class BuildingEditModalComponent {
    public addressValidated: boolean = false;
    constructor(
        @Inject('building') public building: Building,
        @Inject('title') public title: string,
        @Inject('saveFn') public saveFn: (building: Building) => void,
        @Inject('regionIdReadonly') public regionIdReadonly: boolean,
        @Inject('regions') public regions: Observable<Region[]>,
        @Inject(ActiveModal) public activeModal: ActiveModal
    ) {}

    public updateBuilding(address: Address) {
        this.building.city = address.city;
        this.building.postalCode = address.postalCode;
        this.building.houseNumber = address.houseNumber;
        this.building.street = address.street;
        this.building.latitude = address.latitude;
        this.building.longitude = address.longitude;
    }
}
