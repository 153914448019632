import { AfterViewInit, Component, Inject, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Module } from '@obo-main/services/modules/module.models';
import { Region } from '@obo-main/services/region/region.models';
import { Constants } from 'app/constants';
import { DataGridComponent } from '@obo-admin/dataGrid/dataGrid.component';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { DataGridService } from '@obo-admin/dataGrid/services/dataGrid.service';
import { DataGridExcelExportEvent, DataGridState } from '@obo-admin/dataGrid/models/dataGrid.models';
import { DataGridServiceFactory } from '@obo-admin/factories/dataGridService.factory';
import { ModalService } from '@obo-main/services/modal/modal.service';
import { ModalRef } from '@obo-main/services/modal/modalRef.model';

@Component({
    selector: 'adm-region-region-management',
    templateUrl: './regionManagement.component.html',
    styleUrls: ['./regionManagement.component.scss']
})
export class RegionManagementComponent implements AfterViewInit {
    public selectedRow?: any;
    public formGroup: UntypedFormGroup;
    @ViewChild('grid')
    public grid: DataGridComponent;
    public dataGridState: DataGridState = {
        filterState: [],
        sortingState: [],
        paginationState: {
            top: 10,
            skip: 0
        }
    };
    public dataGridService: DataGridService;

    @ViewChild('form')
    formModal: TemplateRef<any>;
    formModalRef: ModalRef;

    public get formModules() {
        return <UntypedFormArray>this.formGroup!.controls.modules;
    }

    constructor(
        private formBuilder: UntypedFormBuilder,
        @Inject('APIPREFIX') private apiPrefix: string,
        private translateService: TranslateService,
        private modalService: ModalService,
        public dataGridServiceFactory: DataGridServiceFactory,
        private viewContainerRef: ViewContainerRef
    ) {
        this.dataGridService = this.dataGridServiceFactory.getService(`${this.apiPrefix}Administration/Regions`);
    }

    public createFormGroup(args: any): UntypedFormGroup {
        const item = (args.isNew ? {} : args.dataItem) as Region & {
            modules: Module[];
            isPriceUsed: boolean;
            isPriceVisible: boolean;
        };

        this.formGroup = this.formBuilder.group({
            id: [item.id, Validators.required],
            catalogUrl: [item.catalogUrl, Validators.required],
            name: [item.name, Validators.required],
            displayName: [item.displayName, Validators.required],
            isPriceUsed: item.isPriceUsed,
            isPriceVisible: item.isPriceVisible,
            isElbridgeEnabled: item.isElbridgeEnabled,
            globalLocationNumber: item.globalLocationNumber,
            modules: this.formBuilder.array(
                (item.modules || []).map((m) =>
                    this.formBuilder.group(
                        Object.entries(m).reduce((obj, [key, value]) => {
                            obj[key] = value;
                            return obj;
                        }, {} as any)
                    )
                )
            )
        });
        return this.formGroup;
    }

    public ngAfterViewInit(): void {
        this.grid.dataGridStateChange
            .pipe(
                debounceTime(700),
                distinctUntilChanged(),
                tap((state) => (this.dataGridState = state))
            )
            .subscribe((state) => this.dataGridService.read(state));
    }

    public saveHandler(): void {
        const value = this.formGroup!.value;
        if (value.id) {
            this.dataGridService.update(value);
        } else {
            this.dataGridService.create(value);
        }
        this.formModalRef.close();
    }

    public selectRow(selectedRow: any): void {
        this.editHandler({ dataItem: selectedRow });
    }

    public editHandler({ dataItem }: any) {
        this.formGroup = this.createFormGroup({ isNew: false, dataItem });
        this.formModalRef = this.modalService.open(this.formModal, { viewContainerRef: this.viewContainerRef });
    }

    public cancelHandler() {
        this.formModalRef.dismiss();
        this.formGroup = undefined;
    }

    public close(): void {
        this.formModalRef.close();
    }

    public onExcelExport(e: DataGridExcelExportEvent): void {
        this.dataGridService.getAllEntries().subscribe((result) => {
            const rows = [];
            const headerCells = [];
            headerCells.push(this.translateService.instant('LANGUAGES_NAME'));
            headerCells.push(this.translateService.instant('LANGUAGES_DISPLAYNAME'));
            headerCells.push(this.translateService.instant('ADMIN_REGIONMANAGEMENT_CATALOGURL'));
            headerCells.push(this.translateService.instant('ADMIN_REGIONMANAGEMENT_GLOBALLOCATIONNUMBER'));
            headerCells.push(this.translateService.instant('ADMIN_REGIONMANAGEMENT_USEPRICEVALIDATION'));
            headerCells.push(this.translateService.instant('ADMIN_REGIONMANAGEMENT_SHOWREGIONPRICES'));
            headerCells.push(this.translateService.instant('ADMIN_REGIONMANAGEMENT_ISELBRIDGEENABLED'));
            result.items[0].modules.forEach((module: any) => headerCells.push(module.name));
            rows.push(headerCells);
            result.items.forEach((region: any) => {
                const cells = [];
                cells.push(region.name);
                cells.push(region.displayName);
                cells.push(region.catalogUrl);
                cells.push(region.globalLocationNumber);
                cells.push(region.isPriceUsed ? Constants.Symbols.booleanTrue : Constants.Symbols.booleanFalse);
                cells.push(region.isPriceVisible ? Constants.Symbols.booleanTrue : Constants.Symbols.booleanFalse);
                cells.push(region.isElbridgeEnabled ? Constants.Symbols.booleanTrue : Constants.Symbols.booleanFalse);
                region.modules.forEach((module: any) =>
                    cells.push(module.isActive ? Constants.Symbols.booleanTrue : Constants.Symbols.booleanFalse)
                );
                rows.push(cells);
            });
            e.updatedRows.next(rows);
        });
    }
}
