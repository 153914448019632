import { Component, ContentChildren, Input, QueryList } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RadioGroupOptionDirective } from '@obo-common/shared/components/radioGroup/radioGroupOption.directive';

let radioGroupId: number = 0;

@Component({
    selector: 'shd-radio-group',
    templateUrl: './radioGroup.component.html',
    styleUrls: ['./radioGroup.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: RadioGroupComponent
        }
    ]
})
export class RadioGroupComponent implements ControlValueAccessor {
    @Input()
    public groupName: string;
    @Input()
    public groupClass: string;
    @Input()
    public optionClass: string;
    @Input()
    public direction: 'vertical' | 'horizontal' = 'horizontal';
    @ContentChildren(RadioGroupOptionDirective)
    public optionTemplates: QueryList<RadioGroupOptionDirective>;
    public id: string = `radioGroup_${radioGroupId++}`;
    selectedValue: any;
    disabled: boolean;

    onChange = (value: any) => {};

    onTouched = () => {};

    touched = false;

    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(value: any): void {
        this.selectedValue = value;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    selectOption(option: RadioGroupOptionDirective): void {
        if (this.selectedValue !== option.value) {
            this.markAsTouched();
            this.writeValue(option.value);
            this.onChange(option.value);
            option.selected.emit(option.value);
        }
    }
}
