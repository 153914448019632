export class ContactFormUser {
    public title: string;
    public firstName: string;
    public lastName: string;
    public company: string;
    public customerGroup: string;
    public street: string;
    public houseNumber: string;
    public postalCode: string;
    public city: string;
    public region: string;
    public phoneNumber: string;
    public email: string;
    public message: string;
    public jobTitle: string;
}
