import { Component, ViewChild } from '@angular/core';
import { SidebarComponent } from '@obo-common/shared/components/sidebar/sidebar.component';
import { ExtendedPlanning } from '@obo-dashboard/models/projectManagement.models';

@Component({
    selector: 'app-configuration-detail-bar',
    templateUrl: './configuration-detail-bar.component.html',
    styleUrl: './configuration-detail-bar.component.scss'
})
export class ConfigurationDetailBarComponent {
    public selectedPlanning: ExtendedPlanning;

    @ViewChild(SidebarComponent)
    public sidebar: SidebarComponent;

    public toggleSidebar(event): void {
        this.selectedPlanning = event;
        this.sidebar.toggle();
    }
}
