import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Logger } from '@obo-main/utils/logger/logger.service';
import { ContactFormUser } from '@obo-static/models/static.models';
import { Observable } from 'rxjs';

@Injectable()
export class ContactFormService {
    constructor(@Inject('APIPREFIX') private apiPrefix: string, private http: HttpClient, private logger: Logger) {}

    /**
     * Sends the form to Api
     * @param user
     */
    public sendForm(user: ContactFormUser): Observable<any> {
        this.logger.debug(`Sending contactForm:`, user);
        return this.http.post(`${this.apiPrefix}Contacts`, user);
    }
}
