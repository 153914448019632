<div class="databaseLookup-detail">
    <adm-data-grid [data]="data" [isClientSide]="true" [dataGridState]="dataGridState" [selectable]="false">
        <adm-data-grid-column field="attributeId"
                              title="{{ 'DATABASELOOKUP_VALUES_ATTRIBUTEID' | translate }}"></adm-data-grid-column>
        <adm-data-grid-column field="attributeName"
                              title="{{ 'DATABASELOOKUP_VALUES_ATTRIBUTENAME' | translate }}"></adm-data-grid-column>
        <adm-data-grid-column field="value"
                              title="{{ 'DATABASELOOKUP_VALUES_ATTRIBUTEVALUE' | translate }}"></adm-data-grid-column>
        <adm-data-grid-column field="valueId"
                              title="{{ 'DATABASELOOKUP_VALUES_VALUEID' | translate }}"></adm-data-grid-column>
    </adm-data-grid>
</div>
