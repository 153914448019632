import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '@obo-main/services/common/alert/alert.service';
import { ContactFormService } from '@obo-static/contact/contactForm/contactForm.service';
import { ContactFormUser } from '@obo-static/models/static.models';
import { SpinnerService } from '@obo-main/services/common/spinner/spinner.service';

@Component({
    selector: 'app-contact-form',
    templateUrl: './contactForm.component.html',
    styleUrls: ['./contactForm.component.scss']
})
export class ContactFormComponent implements OnInit {
    @ViewChild('contactForm')
    public form: NgForm;
    @Input()
    public user: ContactFormUser = new ContactFormUser();
    @Input()
    public message: string;
    reCaptchaValid: boolean;

    constructor(
        private translateService: TranslateService,
        private contactFormService: ContactFormService,
        private alertService: AlertService,
        private spinnerService: SpinnerService
    ) {}

    public ngOnInit(): void {
        if (!this.user) {
            this.user = new ContactFormUser();
        }
        this.user.message = this.message;
    }

    /**
     * submits the form and initiates the download process
     */
    public submit(): void {
        if (this.user.customerGroup) {
            this.user.customerGroup = this.translateService.instant(this.user.customerGroup);
        }
        this.spinnerService.startSpinner();
        this.contactFormService.sendForm(this.user).subscribe({
            next: () => {
                this.alertService.success(this.translateService.instant('ABOUT_CONTACTFORM_SENTSUCCESSFULL'));
                this.user = new ContactFormUser();
                this.form.reset();
            },
            error: (error) => {
                this.alertService.danger(this.translateService.instant('ABOUT_CONTACTFORM_SENTFAILURE'));
            },
            complete: () => {
                this.spinnerService.stopSpinner();
            }
        });
    }
}
