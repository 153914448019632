import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OverlayRef } from '@angular/cdk/overlay';
import { ModalOptions } from '@obo-main/services/modal/modalOptions.model';

/**
 * A reference to the newly opened modal returned by the `Modal.open()` method.
 */
export class ModalRef {
    private _overlayRef: OverlayRef;
    private _closed = new Subject<any>();
    private _dismissed = new Subject<any>();
    private _hidden = new Subject<void>();
    private _resolve: (result?: any) => void;
    private _reject: (reason?: any) => void;

    /**
     * The promise that is resolved when the modal is closed and rejected when the modal is dismissed.
     */
    result: Promise<any>;

    /**
     * The observable that emits when the modal is closed via the `.close()` method.
     *
     * It will emit the result passed to the `.close()` method.
     */
    get closed(): Observable<any> {
        return this._closed.asObservable().pipe(takeUntil(this._hidden));
    }

    /**
     * The observable that emits when the modal is dismissed via the `.dismiss()` method.
     *
     * It will emit the reason passed to the `.dismissed()` method by the user, or one of the internal
     * reasons like backdrop click or ESC key press.
     */
    get dismissed(): Observable<any> {
        return this._dismissed.asObservable().pipe(takeUntil(this._hidden));
    }

    /**
     * The observable that emits when both modal window and backdrop are closed and animations were finished.
     * At this point modal and backdrop elements will be removed from the DOM tree.
     *
     * This observable will be completed after emitting.
     */
    get hidden(): Observable<void> {
        return this._hidden.asObservable();
    }

    constructor(overlayRef: OverlayRef, options: ModalOptions = {}) {
        this._overlayRef = overlayRef;

        this.result = new Promise((resolve, reject) => {
            this._resolve = resolve;
            this._reject = reject;
        });
        this.result.then(null, () => {});

        this._overlayRef
            .backdropClick()
            .pipe(takeUntil(this._hidden))
            .subscribe((event: MouseEvent) => {
                options.isBackdropClickable === false ? event.preventDefault() : this.dismiss();
            });

        if (options.keyboard === false) {
            this._overlayRef
                .keydownEvents()
                .pipe(takeUntil(this._hidden))
                .subscribe((event: KeyboardEvent) => {
                    event.key === 'Escape' ? this.dismiss() : event.preventDefault();
                });
        }
    }

    /**
     * Closes the modal with an optional `result` value.
     *
     * The `MobalRef.result` promise will be resolved with the provided value.
     */
    close(result?: any): void {
        this._closed.next(result);
        this._overlayRef.detach();
        this._resolve(result);
    }

    private _dismiss(reason?: any) {
        this._dismissed.next(reason);
        this._overlayRef.detach();
        this._reject(reason);
    }

    /**
     * Dismisses the modal with an optional `reason` value.
     *
     * The `ModalRef.result` promise will be rejected with the provided value.
     */
    dismiss(reason?: any): void {
        this._dismiss(reason);
    }
}
