import { AfterViewInit, Component, DoCheck, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { User } from '@obo-account/models/account.models';
import { DesktopOnlyModalComponent } from '@obo-common/shared/components/desktopOnlyModal/desktopOnlyModal.component';
import { Project } from '@obo-dashboard/models/projectManagement.models';
import { ProjectService } from '@obo-dashboard/services/project.service';
import { AlertService } from '@obo-main/services/common/alert/alert.service';
import { SpinnerService } from '@obo-main/services/common/spinner/spinner.service';
import { Module } from '@obo-main/services/modules/module.models';
import { RegionService } from '@obo-main/services/region/region.service';
import { SEOService } from '@obo-main/services/seo/seo.service';
import { PortalFeature, PortalRegion } from '@obo-portal/models/portal.models';
import { StateService } from '@uirouter/angular';
import { Constants } from 'app/constants';
import { ProjectEditModalComponent } from './project/modals/project-edit-modal.component';
import { finalize } from 'rxjs/operators';
import { GuidedTour, Orientation } from '@obo-libraries/ngx-guided-tour/guided-tour.constants';
import { TourService } from '@obo-main/services/tour/tour.service';
import { ModalService } from '@obo-main/services/modal/modal.service';
import { ModalRef } from '@obo-main/services/modal/modalRef.model';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, DoCheck, AfterViewInit {
    @Input()
    public projectList: Project[];
    @Input()
    public modules: Module[];
    @Input()
    public user: User;
    @Input()
    public isMobile: boolean = false;

    public today: Date = new Date();
    public showAllProjects: boolean = false;
    public filteredProjectList: Project[];
    public plannableModules: Module[];
    public plannableModuleIDs: number[] = [];
    public activeFilters: number[] = [];
    public selectedRegion: PortalRegion;
    public language: string = this.translateService.currentLang;
    private searchTerm: string;

    @ViewChild('desktopOnlyModalTpl')
    public desktopOnlyModal: DesktopOnlyModalComponent;

    private editProjectModalRef: ModalRef;

    constructor(
        private stateService: StateService,
        private projectService: ProjectService,
        private regionService: RegionService,
        private translateService: TranslateService,
        private spinnerService: SpinnerService,
        private alertService: AlertService,
        private seo: SEOService,
        private modalService: ModalService,
        private tourService: TourService
    ) {}

    public ngOnInit(): void {
        this.seo.updateTitle(this.translateService.instant('DASHBOARD_META_TITLE'));

        this.translateService.onLangChange.subscribe((langChange: any) => {
            this.language = langChange.lang;
        });

        this.sortProjectList();
        this.filteredProjectList = this.projectList;
        this.updateInFeatures();
    }

    public ngDoCheck(): void {
        if (this.selectedRegion !== this.regionService.selectedPortalRegion) {
            this.selectedRegion = this.regionService.selectedPortalRegion;
            this.updateInFeatures();
        }
    }

    public addProject(): void {
        const saveProjectFn = (project: Project): void => {
            project.usageDate = new Date().toISOString();
            this.spinnerService.startSpinner();
            this.projectService
                .createOrUpdateProject(project)
                .pipe(
                    finalize((): void => {
                        this.spinnerService.stopSpinner();
                        this.dismissModal();
                    })
                )
                .subscribe({
                    next: (res: Project): void => {
                        this.alertService.success(this.translateService.instant('PROJECTCTRL_SAVEPROJECTSUCCESS'));
                        this.stateService.go('project', {
                            projectId: res.id
                        });
                    },
                    error: (): void => {
                        this.alertService.danger(this.translateService.instant('PROJECTCTRL_SAVEPROJECTFAILURE'));
                    }
                });
        };

        this.editProjectModalRef = this.modalService.open(ProjectEditModalComponent, {
            injector: Injector.create({
                providers: [
                    {
                        provide: 'project',
                        useValue: new Project()
                    },
                    {
                        provide: 'title',
                        useValue: this.translateService.instant('PROJECTS_ADDNEW')
                    },
                    {
                        provide: 'saveFn',
                        useValue: saveProjectFn
                    },
                    {
                        provide: 'dismissFn',
                        useValue: () => this.dismissModal()
                    }
                ]
            })
        });
    }

    public filterProjects(value: string): void {
        this.searchTerm = value;
        this.showAllProjects = true;
        let filteredProjects: Project[] = this.projectList;
        if (this.activeFilters.length) {
            filteredProjects = filteredProjects.filter(
                (p: Project) => p.plannedModules.length && this.activeFilters.every((id) => p.plannedModules.includes(id))
            );
        }
        if (value) {
            filteredProjects = filteredProjects.filter(
                (p: Project) =>
                    p.name.toLowerCase().includes(value.toLowerCase()) ||
                    p.customerName?.toLowerCase().includes(value.toLowerCase())
            );
        }
        this.filteredProjectList = filteredProjects;
    }

    public updateFilters(id: number): void {
        if (this.activeFilters.includes(id)) {
            const index: number = this.activeFilters.indexOf(id, 0);
            this.activeFilters.splice(index, 1);
        } else {
            this.activeFilters.push(id);
        }
        this.filterProjects(this.searchTerm);
    }

    public reloadProjects() {
        this.projectService.getProjectList().subscribe((projects: Project[]): void => {
            this.projectList = projects;
            this.sortProjectList();
            this.filteredProjectList = this.projectList;
        });
    }

    public openWarningModal(): void {
        this.desktopOnlyModal.open();
    }

    private updateInFeatures(): void {
        this.regionService.selectedPortalRegion.features.forEach((f: PortalFeature): void => {
            let moduleId: number = Constants.PortalFeaturesToModules[f.id];
            if (moduleId) this.plannableModuleIDs.push(moduleId);
        });
        this.plannableModules = this.modules.filter((m) => this.plannableModuleIDs.includes(m.id));
    }

    private dismissModal() {
        this.editProjectModalRef.dismiss();
    }

    private sortProjectList() {
        this.projectList = this.projectList.sort((a, b) => Date.parse(b.usageDate) - Date.parse(a.usageDate));
    }

    public ngAfterViewInit(): void {
        this.tourService.init(this.dashboardTour);
    }

    private get dashboardTour(): GuidedTour {
        return {
            tourId: Constants.storageTokens.seen_tour_list.dashboard,
            steps: [
                {
                    title: this.translateService.instant('DASHBOARD_TOUR_STEP_1_TITLE'),
                    content: this.translateService.instant('DASHBOARD_TOUR_STEP_1_CONTENT')
                },
                {
                    title: this.translateService.instant('DASHBOARD_TOUR_STEP_2_TITLE'),
                    selector: '.quick-start',
                    content: this.translateService.instant('DASHBOARD_TOUR_STEP_2_CONTENT'),
                    orientation: Orientation.Bottom
                },
                {
                    title: this.translateService.instant('DASHBOARD_TOUR_STEP_3_TITLE'),
                    selector: '.add-project-card',
                    content: this.translateService.instant('DASHBOARD_TOUR_STEP_3_CONTENT'),
                    orientation: Orientation.Bottom
                },
                {
                    title: this.translateService.instant('DASHBOARD_TOUR_STEP_4_TITLE'),
                    selector: '#projectEditModal',
                    content: this.translateService.instant('DASHBOARD_TOUR_STEP_4_CONTENT'),
                    action: () => this.addProject(),
                    closeAction: () => this.dismissModal(),
                    backstepAction: () => this.dismissModal(),
                    orientation: Orientation.Right
                },
                {
                    title: this.translateService.instant('DASHBOARD_TOUR_STEP_5_TITLE'),
                    selector: '.project-search',
                    content: this.translateService.instant('DASHBOARD_TOUR_STEP_5_CONTENT'),
                    orientation: Orientation.Left
                },
                {
                    title: this.translateService.instant('DASHBOARD_TOUR_STEP_FILTER_TITLE'),
                    selector: '.project-filters',
                    content: this.translateService.instant('DASHBOARD_TOUR_STEP_FILTER_CONTENT'),
                    orientation: Orientation.Left
                },
                {
                    title: this.translateService.instant('DASHBOARD_TOUR_STEP_6_TITLE'),
                    selector: '#showMoreProjectsBtn',
                    content: this.translateService.instant('DASHBOARD_TOUR_STEP_6_CONTENT'),
                    orientation: Orientation.Right
                }
            ]
        };
    }
}
