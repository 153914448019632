import { Component, ContentChild, Input } from '@angular/core';
import { DataGridCellDirective } from '@obo-admin/dataGrid/directives/dataGridCell.directive';
import { DataGridFilterMenuDirective } from '@obo-admin/dataGrid/directives/dataGridFilterMenu.directive';
import { DataGridFilterService } from '@obo-admin/dataGrid/services/dataGridFilter.service';

@Component({
    selector: 'adm-data-grid-column',
    template: ''
})
export class DataGridColumnComponent {
    @Input()
    public field: string;
    @Input()
    public title: string;
    @Input()
    public filterable: boolean = true;
    @Input()
    public sortable: boolean = true;
    @Input()
    public editable: boolean = true;
    @Input()
    public width: number;
    @Input()
    public hidden: boolean;
    @Input()
    public isMulti: boolean;
    @Input()
    type: 'number' | 'text' | 'date' = 'text';

    @ContentChild(DataGridCellDirective)
    cellTemplate: DataGridCellDirective;

    @ContentChild(DataGridFilterMenuDirective)
    filterMenuTemplate: DataGridFilterMenuDirective;

    filterService: DataGridFilterService;

    public initFilterService(filterService: DataGridFilterService): void {
        this.filterService = filterService;
    }
}
