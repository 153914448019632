import { ComponentRef, Directive, ElementRef, forwardRef, Input, Renderer2, ViewContainerRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { ErrorFieldComponent } from '@obo-common/shared/components/errorField/errorField.component';

@Directive({
    selector: '[formControlName][shdValidateInput],[formControl][shdValidateInput],[ngModel][shdValidateInput]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ValidateInputDirective),
            multi: true
        }
    ]
})
export class ValidateInputDirective implements Validator {
    @Input()
    public customErrorMsg: string;
    @Input()
    public wrapperClass: string;
    private errorFieldComponent: ComponentRef<ErrorFieldComponent>;

    constructor(private element: ElementRef, private renderer: Renderer2, private viewContainerRef: ViewContainerRef) {}

    public validate(control: AbstractControl): ValidationErrors | null {
        if (control) {
            if (!this.errorFieldComponent) {
                this.initErrorFieldComponent();
            }
            this.initValidation(control);
        }
        return null;
    }

    private initValidation(control: AbstractControl): void {
        this.errorFieldComponent.instance.control = control;
        if (this.customErrorMsg) {
            this.errorFieldComponent.instance.customErrorMsg = this.customErrorMsg;
        }
    }

    private initErrorFieldComponent(): void {
        this.errorFieldComponent = this.viewContainerRef.createComponent<ErrorFieldComponent>(ErrorFieldComponent);
        const parentElement = this.isWrapped()
            ? this.element.nativeElement.parentElement.parentElement
            : this.element.nativeElement.parentElement;
        parentElement.appendChild(this.errorFieldComponent.location.nativeElement);
    }

    private isWrapped(): boolean {
        const parentClass = this.element.nativeElement.parentElement.getAttribute('class');
        return this.wrapperClass
            ? parentClass?.includes(this.wrapperClass)
            : parentClass?.includes('position-relative') || parentClass?.includes('unit-input');
    }
}
