import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { DataGridFilterService } from '@obo-admin/dataGrid/services/dataGridFilter.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'adm-data-grid-filter',
    templateUrl: './dataGridFilter.component.html',
    styleUrls: ['./dataGridFilter.component.scss']
})
export class DataGridFilterComponent {
    @Input()
    public field: string;
    @Input()
    public filterService: DataGridFilterService;
    @Input()
    public filterMenuTemplate: TemplateRef<any>;
    @Input()
    type: 'number' | 'text' | 'date';

    @ViewChild(NgbDropdown)
    dropDown: NgbDropdown;

    get active(): boolean {
        return this.filterService && this.filterService.isFilterActive(this.field);
    }

    public closeDropDown(): void {
        this.dropDown.close();
    }
}
