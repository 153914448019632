import { Component, Injector, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Project } from '@obo-dashboard/models/projectManagement.models';
import { ProjectService } from '@obo-dashboard/services/project.service';
import { AlertService } from '@obo-main/services/common/alert/alert.service';
import { SpinnerService } from '@obo-main/services/common/spinner/spinner.service';
import { StateService } from '@uirouter/core';
import { finalize } from 'rxjs/operators';
import { ProjectEditModalComponent } from '../modals/project-edit-modal.component';
import { ModalService } from '@obo-main/services/modal/modal.service';
import { ModalRef } from '@obo-main/services/modal/modalRef.model';

@Component({
    selector: 'app-project-detail',
    templateUrl: './project-detail.component.html',
    styleUrls: ['./project-detail.component.scss']
})
export class ProjectDetailComponent {
    @Input()
    public project: Project;
    public editProjectModalRef: ModalRef;

    constructor(
        private projectService: ProjectService,
        private alertService: AlertService,
        private spinnerService: SpinnerService,
        private stateService: StateService,
        private translateService: TranslateService,
        private modalService: ModalService
    ) {}

    public editProject(): void {
        const projectClone = Object.assign({}, this.project);
        this.editProjectModalRef = this.modalService.open(ProjectEditModalComponent, {
            injector: Injector.create({
                providers: [
                    {
                        provide: 'project',
                        useValue: projectClone
                    },
                    {
                        provide: 'title',
                        useValue: this.translateService.instant('PROJECT_EDIT_PROJECT')
                    },
                    {
                        provide: 'saveFn',
                        useValue: (project: Project) => {
                            this.saveProject(project);
                        }
                    }
                ]
            })
        });
    }

    public deleteProject(): void {
        this.spinnerService.startSpinner();
        this.projectService
            .deleteProject(this.project)
            .pipe(finalize(() => this.spinnerService.stopSpinner()))
            .subscribe({
                next: (res: Project) => {
                    this.alertService.success(this.translateService.instant('PROJECTCTRL_DELETEPROJECTSUCCESS'));
                    this.stateService.go('dashboard');
                },
                error: (error: any) => {
                    this.alertService.danger(this.translateService.instant('PROJECTCTRL_DELETEPROJECTFAILURE'));
                }
            });
    }

    /**
     * duplicates a project
     * @param building
     */
    public duplicateProject(): void {
        this.spinnerService.startSpinner();
        this.projectService
            .duplicateProject(this.project)
            .pipe(finalize(() => this.spinnerService.stopSpinner()))
            .subscribe({
                next: (project) => {
                    this.alertService.success(this.translateService.instant('MAIN_DUPLICATEPROJECT_SUCCESS'));
                    this.stateService.go('projectDetail', {
                        projectId: project.id
                    });
                },
                error: () => {
                    this.alertService.danger(this.translateService.instant('MAIN_DUPLICATEPROJECT_FAILURE'));
                }
            });
    }

    private saveProject(project: Project) {
        this.spinnerService.startSpinner();
        this.projectService
            .createOrUpdateProject(project)
            .pipe(finalize(() => this.spinnerService.stopSpinner()))
            .subscribe({
                next: () => {
                    this.project = project;
                    this.editProjectModalRef.close();
                    this.alertService.success(this.translateService.instant('SHARED_EDIT_SUCCESS'));
                },
                error: () => {
                    this.alertService.danger(this.translateService.instant('SAVE_PROJECT_FAILURE'));
                }
            });
    }
}
