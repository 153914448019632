import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';
import { ACCOUNT_STATES } from '@obo-account/account.states';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedDirectivesModule } from '@obo-common/shared/sharedDirectives.module';
import { SharedPipesModule } from '@obo-common/shared/sharedPipes.module';
import { SharedComponentsModule } from '@obo-common/shared/sharedComponents.module';
import { AccountComponent } from '@obo-account/account/account.component';
import { LoginComponent } from '@obo-account/login/login.component';
import { LandingComponent } from '@obo-account/landing/landing.component';
import { RegisterComponent } from '@obo-account/register/register.component';
import { ElbridgeEntryComponent } from '@obo-account/elbridgeEntry/elbridgeEntry.component';
import { ConfirmAccountComponent } from '@obo-account/confirmAccount/confirmAccount.component';
import { RequestPasswordComponent } from '@obo-account/requestPassword/requestPassword.component';
import { ResetPasswordComponent } from '@obo-account/resetPassword/resetPassword.component';
import { AccountService } from '@obo-account/account/account.service';
import { RegisterService } from '@obo-account/register/register.service';
import { ConfirmAccountService } from '@obo-account/confirmAccount/confirmAccount.service';
import { RequestPasswordService } from '@obo-account/requestPassword/requestPassword.service';
import { ResetPasswordService } from '@obo-account/resetPassword/resetPassword.service';
import { RegisterModalComponent } from '@obo-account/registerModal/registerModal.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AlreadyRegisteredComponent } from '@obo-account/alreadyRegistered/alreadyRegistered.component';
import { RegisterWaitingComponent } from '@obo-account/registerWaiting/registerWaiting.component';

/**
 * Module containing Account, Register/Login related Components and Services.
 */
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        UIRouterModule.forChild({ states: ACCOUNT_STATES }),
        NgbModule,
        ReactiveFormsModule,
        SharedDirectivesModule,
        SharedPipesModule,
        SharedComponentsModule,
        FontAwesomeModule
    ],
    declarations: [
        AccountComponent,
        LoginComponent,
        LandingComponent,
        RegisterComponent,
        ElbridgeEntryComponent,
        ConfirmAccountComponent,
        RequestPasswordComponent,
        ResetPasswordComponent,
        RegisterModalComponent,
        AlreadyRegisteredComponent,
        RegisterWaitingComponent
    ],
    providers: [AccountService, RegisterService, ConfirmAccountService, RequestPasswordService, ResetPasswordService]
})
export class AccountModule {}
