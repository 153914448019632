import { RootComponent } from '@obo-main/components/root/root.component';
import { Ng2StateDeclaration } from '@uirouter/angular';

/**
 * This is the parent state for the entire application.
 *
 * This state's primary purposes are:
 * 1) Shows the outermost chrome (including the navigation and logout for authenticated users)
 * 2) Provide a viewport (ui-view) for a substate to plug into
 */
export const appState = {
    name: 'app',
    url: '?enableregister?{lang:string}?{gclid:string}',
    redirectTo: 'login',
    component: RootComponent,
    data: {
        module: 'main'
    },
    params: {
        lang: {
            type: 'string'
        },
        enableregister: {
            value: false,
            type: 'bool',
            squash: true
        }
    }
};

// This future state is a placeholder for the lazy loaded Admin Module
export const adminFutureState = {
    name: 'admin.**',
    url: '/admin',
    data: {
        lazyLoad: true,
        preLoad: false
    },
    loadChildren: () => import('./admin/admin.module').then((result) => result.AdminModule)
};

export const portalFutureState = {
    name: 'portal.**',
    url: '/portal',
    data: {
        lazyLoad: true,
        preLoad: true
    },
    loadChildren: () => import('./portal/portal.module').then((result) => result.PortalModule)
};

export const ufsFutureState = {
    name: 'underfloor.**',
    url: '/configurators/underfloor?{projectId:int}?{buildingId:int}?{planningId:int}',
    data: {
        lazyLoad: true,
        preLoad: true
    },
    loadChildren: () => import('./ufs/ufsConfigurator.module').then((result) => result.UfsConfiguratorModule)
};

export const incendiaryFutureState: Ng2StateDeclaration = {
    name: 'incendiary.**',
    url: '/configurators/incendiary?{projectId:int}?{buildingId:int}?{planningId:int}',
    data: {
        lazyLoad: true,
        preLoad: true
    },
    loadChildren: () => import('./incendiary/incendiaryConfigurator.module').then((result) => result.IncendiaryConfiguratorModule)
};

export const fillingFutureState: Ng2StateDeclaration = {
    name: 'filling.**',
    url: '/configurators/filling?{projectId:int}?{buildingId:int}?{planningId:int}',
    data: {
        lazyLoad: true,
        preLoad: true
    },
    loadChildren: () => import('./fillingCalculator/fillingCalculator.module').then((result) => result.FillingCalculatorModule)
};

export const groundingFutureState: Ng2StateDeclaration = {
    name: 'grounding.**',
    url: '/configurators/grounding?{projectId:int}?{buildingId:int}?{planningId:int}?{regionId:int}?region',
    params: { planningId: null, regionId: null, region: null },
    data: {
        lazyLoad: true,
        preLoad: true
    },
    loadChildren: () => import('./grounding/groundingConfigurator.module').then((result) => result.GroundingConfiguratorModule)
};

export const pvFutureState: Ng2StateDeclaration = {
    name: 'photovoltaic.**',
    url: '/configurators/photovoltaic?{projectId:int}?{buildingId:int}?{planningId:int}?{regionId:int}?region',
    params: { planningId: null, regionId: null, region: null },
    data: {
        lazyLoad: true,
        preLoad: true
    },
    loadChildren: () =>
        import('./photovoltaic/photovoltaicConfigurator.module').then((result) => result.PhotovoltaicConfiguratorModule)
};

export const overvoltageFutureState: Ng2StateDeclaration = {
    name: 'overvoltage.**',
    url: '/configurators/overvoltage?{projectId:int}?{buildingId:int}?{planningId:int}',
    data: {
        lazyLoad: true,
        preLoad: true
    },
    loadChildren: () =>
        import('./overvoltage/overvoltageConfigurator.module').then((result) => result.OvervoltageConfiguratorModule)
};

export const egressFutureState: Ng2StateDeclaration = {
    name: 'egress.**',
    url: '/configurators/egress?{projectId:int}?{buildingId:int}?{planningId:int}',
    data: {
        lazyLoad: true,
        preLoad: true
    },
    loadChildren: () => import('./egress/egressConfigurator.module').then((result) => result.EgressConfiguratorModule)
};

export const APP_STATES: any = [
    appState,
    adminFutureState,
    ufsFutureState,
    incendiaryFutureState,
    fillingFutureState,
    portalFutureState,
    overvoltageFutureState,
    groundingFutureState,
    pvFutureState,
    egressFutureState
];
