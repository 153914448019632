import { Component, Injector, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Building, ExtendedPlanning, Project } from '@obo-dashboard/models/projectManagement.models';
import { BuildingService } from '@obo-dashboard/services/building.service';
import { ProjectService } from '@obo-dashboard/services/project.service';
import { AlertService } from '@obo-main/services/common/alert/alert.service';
import { SpinnerService } from '@obo-main/services/common/spinner/spinner.service';
import { Region } from '@obo-main/services/region/region.models';
import { RegionService } from '@obo-main/services/region/region.service';
import { SEOService } from '@obo-main/services/seo/seo.service';
import { Constants } from 'app/constants';
import { Observable, Subject } from 'rxjs';
import { concatAll, finalize, map, mergeMap, toArray } from 'rxjs/operators';
import { BuildingEditModalComponent } from './modals/building-edit-modal.component';
import { PlanningService } from '@obo-main/services/plannings/planning.service';
import { Planning } from '@obo-main/services/plannings/plannings.models';
import { ConfirmModalComponent } from '@obo-common/shared/components/confirmModal/confirmModal.component';
import { ModalService } from '@obo-main/services/modal/modal.service';
import { ModalRef } from '@obo-main/services/modal/modalRef.model';

@Component({
    selector: 'app-project',
    templateUrl: './project.component.html',
    styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit, OnDestroy {
    @Input()
    public project: Project;
    @Input()
    public buildings: Building[];
    @Input()
    public isMobile: boolean;

    public filteredBuildings: Building[];

    public regions: Observable<Region[]>;
    public plannings: ExtendedPlanning[];
    public regionIdReadonly: boolean;
    public moduleId = Constants.ModuleId;
    public selectedBuilding: Building | undefined;
    public planningToImport: string;
    public fileToImport: File;
    @ViewChild(ConfirmModalComponent)
    public importPlanningConfirmModal: ConfirmModalComponent;
    private onDestroy = new Subject<any>();
    public nonImportExportModules: number[] = Constants.NonImportExportModules;
    private buildingEditModalRef: ModalRef;

    constructor(
        private seo: SEOService,
        private buildingService: BuildingService,
        private projectService: ProjectService,
        private spinnerService: SpinnerService,
        private alertService: AlertService,
        private translateService: TranslateService,
        private regionService: RegionService,
        private modalService: ModalService,
        private planningService: PlanningService
    ) {}

    public ngOnInit(): void {
        this.seo.updateTitle(`${this.translateService.instant('PROJECT_META_TITLE')} ${this.project.name}`);

        this.filteredBuildings = this.buildings;
        if (this.filteredBuildings.length > 0) {
            this.selectedBuilding = this.filteredBuildings[0];
        }

        this.regions = this.regionService.getRegions();
    }

    public ngOnDestroy(): void {
        this.onDestroy.next(1);
        this.onDestroy.complete();
    }

    public addBuilding(): void {
        this.buildingEditModalRef = this.modalService.open(BuildingEditModalComponent, {
            injector: Injector.create({
                providers: [
                    {
                        provide: 'building',
                        useValue: { regionId: this.regionService.selectedApplicationRegion?.id } as Building
                    },
                    {
                        provide: 'title',
                        useValue: this.translateService.instant('PROJECT_ADD_BUILDING')
                    },
                    {
                        provide: 'saveFn',
                        useValue: (building: Building) => this.createBuilding(building).then()
                    },
                    {
                        provide: 'dismissFn',
                        useValue: () => this.buildingEditModalRef.dismiss()
                    },
                    {
                        provide: 'regionIdReadonly',
                        useValue: false
                    },
                    {
                        provide: 'regions',
                        useValue: this.regions
                    }
                ]
            })
        });
    }

    public reloadBuildings(selectedBuildingId?: number): void {
        this.projectService
            .getBuildingsByProject(this.project.id)
            .pipe(
                concatAll(),
                mergeMap((b: Building) =>
                    this.buildingService.getPlanningsByBuilding(this.project.id, b.id).pipe(
                        map((p: Planning[]) => {
                            b.plannings = this.buildingService.extendPlannings(p);
                            return b;
                        })
                    )
                ),
                toArray()
            )
            .subscribe((buildings: Building[]): void => {
                this.buildings = buildings;
                this.filteredBuildings = this.buildings;
                this.selectedBuilding = selectedBuildingId
                    ? this.getBuildingById(selectedBuildingId)
                    : this.filteredBuildings
                    ? this.filteredBuildings[0]
                    : undefined;
            });
    }

    public filterBuildings(value: string): void {
        this.filteredBuildings = this.buildings.filter((b: Building) => b.name.toLowerCase().includes(value.toLowerCase()));
    }

    public checkIfPlanningIsImportable(event: any): void {
        this.fileToImport = event.target.files[0];
        if (this.isPlanningPlanned(this.planningToImport)) {
            this.importPlanningConfirmModal.show();
        } else {
            this.importPlanning(this.fileToImport, this.planningToImport);
        }
    }

    public createBuildingAndImportPlanning(): void {
        const newBuilding: Building = new Building();
        newBuilding.regionId = this.regionService.selectedApplicationRegion?.id;
        newBuilding.name = this.translateService.instant('BUILDING_NEW');

        this.createBuilding(newBuilding).then((): void => {
            this.importPlanning(this.fileToImport, this.planningToImport);
        });
    }

    public moduleAllowsImportExport(moduleId: number): boolean {
        return !this.nonImportExportModules.includes(moduleId);
    }

    private importPlanning(fileToImport: File, planningName: string): void {
        const json: FormData = new FormData();
        json.append('planning', fileToImport);
        this.spinnerService.startSpinner();
        this.planningService
            .import(planningName, this.project.id, this.selectedBuilding.id, json)
            .pipe(finalize(() => this.spinnerService.stopSpinner()))
            .subscribe({
                next: (): void => {
                    this.alertService.success(this.translateService.instant('BUILDING_PLANNING_IMPORT_SUCCESS'));
                    this.reloadBuildings();
                    this.fileToImport = null;
                    this.planningToImport = null;
                },
                error: (err) => this.alertService.danger(this.translateService.instant('BUILDING_PLANNING_IMPORT_ERROR', err))
            });
    }

    private isPlanningPlanned(planningName: string): boolean {
        return this.selectedBuilding.plannings.find((p) => p.name === planningName)?.isPlanned;
    }

    private getBuildingById(buildingId: number): Building {
        return this.buildings.find((building: Building): boolean => building.id === buildingId);
    }

    private createBuilding(building: Building): Promise<Building> {
        return new Promise<Building>((resolve): void => {
            this.spinnerService.startSpinner();
            this.buildingService
                .createOrUpdateBuilding(this.project.id, building)
                .pipe(
                    finalize(() => {
                        this.spinnerService.stopSpinner();
                        this.buildingEditModalRef.close();
                    }),
                    mergeMap((b) =>
                        this.buildingService.getPlanningsByBuilding(this.project.id, b.id).pipe(
                            map((p: Planning[]) => {
                                b.plannings = this.buildingService.extendPlannings(p);
                                return b;
                            })
                        )
                    )
                )
                .subscribe({
                    next: (res: Building): void => {
                        this.alertService.success(this.translateService.instant('BUILDINGCTRL_SAVEBUILDINGSUCCESS'));
                        this.buildings.unshift(res);
                        this.filteredBuildings = this.buildings;
                        this.selectedBuilding = res;
                        resolve(building);
                    },
                    error: () => {
                        this.alertService.danger(this.translateService.instant('BUILDINGCTRL_SAVEBUILDINGFAILURE'));
                    }
                });
        });
    }
}
