export class ModuleActionBarStep {
    icon: string;
    label: string;
    name: string;
    optional?: boolean;
}

export class ModuleActionBarStepGroup {
    steps: Array<ModuleActionBarStep>;

    constructor(steps: Array<ModuleActionBarStep>) {
        this.steps = steps;
    }
}

export enum ModuleActionBarIcon {
    //shared
    Entry = 'route',
    ProductSelection = 'cart-plus',
    FinishWizard = 'list-check',

    //ufs
    FloorParameters = 'border-bottom',

    //pv
    Location = 'location-dot',
    WeatherData = 'sun-cloud',
    RoofParameters = 'house-chimney',
    VisualConfiguration = 'solar-panel',
    StructuralAnalysis = 'calculator',

    //incendiary
    WallBreachParameters = 'block-brick-fire',

    //overvoltage
    DistributionMetaData = 'sliders-up',
    DistributionDummy = 'square-dashed',

    //grounding
    GroundingType = 'bore-hole',
    GroundingEntry = 'house-building',
    AdvancedBuildingData = 'building-magnifying-glass',

    //fillingCalculator
    AdvancedConfiguration = 'distribute-spacing-horizontal'
}
