import { AfterViewInit, Component, Inject, OnDestroy, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Cable, CableCategory, CableCategoryWireType } from '@obo-common/shared/models/cable.models';
import { AlertService } from '@obo-main/services/common/alert/alert.service';
import { SpinnerService } from '@obo-main/services/common/spinner/spinner.service';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, tap } from 'rxjs/operators';
import { CableManagementService } from '@obo-admin/productManagement/cableManagement/cableManagement.service';
import { DataGridServiceFactory } from '@obo-admin/factories/dataGridService.factory';
import { DataGridService } from '@obo-admin/dataGrid/services/dataGrid.service';
import { DataGridExcelExportEvent, DataGridResult, DataGridState } from '@obo-admin/dataGrid/models/dataGrid.models';
import { DataGridComponent } from '@obo-admin/dataGrid/dataGrid.component';
import { ModalService } from '@obo-main/services/modal/modal.service';
import { ModalRef } from '@obo-main/services/modal/modalRef.model';

@Component({
    selector: 'adm-router-cable-management',
    templateUrl: './cableManagement.component.html',
    styleUrls: ['./cableManagement.component.scss'],
    providers: [CableManagementService]
})
export class CableManagementComponent implements AfterViewInit, OnDestroy {
    @ViewChild('cableGrid')
    public cableGrid: DataGridComponent;

    @ViewChild('cableCategoryModal')
    cableCategoryModal: TemplateRef<any>;
    cableCategoryModalRef: ModalRef;

    @ViewChild('cableModal')
    cableModal: TemplateRef<any>;
    cableModalRef: ModalRef;

    protected onDestroy = new Subject();

    public cableEditService: DataGridService;
    public cableFormGroup: UntypedFormGroup;
    public cableGridState: DataGridState = {
        filterState: [],
        sortingState: [],
        paginationState: {
            top: 10,
            skip: 0
        }
    };

    @ViewChild('cableCategoryGrid')
    public cableCategoryGrid: DataGridComponent;

    public cableCategoryEditService: DataGridService;
    public cableCategoryFormGroup: UntypedFormGroup;
    public cableCategoryGridState: DataGridState = {
        filterState: [],
        sortingState: [],
        paginationState: {
            top: 10,
            skip: 0
        }
    };

    public cableCategoryWireTypeEnum = CableCategoryWireType;
    public cableCategoryWireTypes = Object.entries(CableCategoryWireType)
        .filter(([key, val]) => typeof val === 'number')
        .map(([key, val]) => val);
    private colorCache: string;
    selectedRow: CableCategory | Cable;
    showCableCategory: boolean = true;

    constructor(
        @Inject('APIPREFIX') private apiPrefix: string,
        private formBuilder: UntypedFormBuilder,
        private dataGridServiceFactory: DataGridServiceFactory,
        private alertService: AlertService,
        private spinnerService: SpinnerService,
        private cableManagementService: CableManagementService,
        private translateService: TranslateService,
        private modalService: ModalService,
        private viewContainerRef: ViewContainerRef
    ) {
        this.cableEditService = this.dataGridServiceFactory.getService(`${this.apiPrefix}Administration/Cables`);
        this.cableCategoryEditService = this.dataGridServiceFactory.getService(`${this.apiPrefix}Administration/CableCategories`);
    }

    public ngOnDestroy(): void {
        this.onDestroy.next(1);
        this.onDestroy.complete();
    }

    public ngAfterViewInit(): void {
        this.cableCategoryGrid.dataGridStateChange
            .pipe(
                debounceTime(700),
                distinctUntilChanged(),
                tap((state) => (this.cableCategoryGridState = state))
            )
            .subscribe((state) => this.cableCategoryEditService.read(state));

        this.cableGrid.dataGridStateChange
            .pipe(
                debounceTime(700),
                distinctUntilChanged(),
                tap((state) => (this.cableGridState = state))
            )
            .subscribe((state) => this.cableEditService.read(state));
    }

    public createCableFormGroup(args: any): UntypedFormGroup {
        const item = args.isNew ? new Cable() : args.dataItem;

        this.cableFormGroup = this.formBuilder.group({
            id: item.id,
            color: item.color,
            categoryId: [item.categoryId, [Validators.required, Validators.min(0)]],
            diameter: [item.diameter, [Validators.required, Validators.min(0)]],
            load: [item.load, [Validators.required, Validators.min(0)]],
            type: [item.type, [Validators.required, Validators.minLength(1)]]
        });
        return this.cableFormGroup;
    }

    public createCableCategoryFormGroup(args: any): UntypedFormGroup {
        const item = args.isNew ? new CableCategory() : args.dataItem;

        this.cableCategoryFormGroup = this.formBuilder.group({
            id: item.id,
            name: [item.name, [Validators.required, Validators.minLength(1)]],
            wireType: [item.wireType, [Validators.required]],
            color: [item.color || this.colorCache || '#000000', Validators.required],
            hasImage: item.hasImage || false
        });

        return this.cableCategoryFormGroup;
    }

    public cancelHandler(isCableCategory?: boolean) {
        if (isCableCategory) {
            this.cableCategoryFormGroup = undefined;
            this.cableCategoryModalRef.dismiss();
        } else {
            this.cableModalRef.dismiss();
            this.cableFormGroup = undefined;
        }
    }

    public saveHandler(isCableCategory?: boolean): void {
        let service: DataGridService;
        let value: Cable | CableCategory;
        if (isCableCategory) {
            this.cableCategoryModalRef.close();
            value = this.cableCategoryFormGroup.value;
            service = this.cableCategoryEditService;
        } else {
            this.cableModalRef.close();
            value = this.cableFormGroup.value;
            service = this.cableEditService;
        }

        if (value.id) {
            service.update(Object.assign(this.selectedRow, value));
        } else {
            service.create(value);
        }
    }

    /**
     * uploads an moduleImage
     * @param evt
     * @param dataItem
     */
    public uploadCableCategoryImage(evt: any, dataItem: any): void {
        let file = evt.target.files[0];
        if (file) {
            this.spinnerService.startSpinner();
            this.cableManagementService
                .uploadCableCategoryImage(file, dataItem)
                .pipe(finalize(() => this.spinnerService.stopSpinner()))
                .subscribe({
                    next: () => {
                        this.alertService.success(this.translateService.instant('ADMINS_FILEUPLOAD_COMPLETE'));
                        this.cableEditService.read(this.cableGridState);
                    },
                    error: () => this.alertService.danger('Error')
                });
        }
    }

    public setColor(formGroup: UntypedFormGroup, color: string): void {
        formGroup.get('color')!.setValue(color);
        formGroup.get('color')!.markAsTouched();
        formGroup.markAsDirty();
        this.colorCache = color;
    }

    public setCableColorFromCategory(category: CableCategory): void {
        this.cableFormGroup.get('color')!.setValue(category.color);
    }

    public create(isCableCategory?: boolean): void {
        this.selectRow(undefined, isCableCategory);
    }

    public selectRow(event: CableCategory | Cable | undefined, isCableCategory?: boolean): void {
        if (event) {
            this.selectedRow = isCableCategory ? CableCategory.fromApiData(event) : Cable.fromApiData(event);
        }
        if (isCableCategory) {
            this.cableCategoryFormGroup = this.createCableCategoryFormGroup({
                isNew: false,
                dataItem: event ?? new CableCategory()
            });
            this.cableCategoryModalRef = this.modalService.open(this.cableCategoryModal, {
                viewContainerRef: this.viewContainerRef
            });
        } else {
            this.cableFormGroup = this.createCableFormGroup({ isNew: false, dataItem: event ?? new Cable() });
            this.cableModalRef = this.modalService.open(this.cableModal, {
                viewContainerRef: this.viewContainerRef
            });
        }
    }

    public getCategoryById(id: number): CableCategory | undefined {
        return id
            ? ((this.cableCategoryGrid.data as DataGridResult).items as CableCategory[]).find((cat) => cat.id === id)
            : undefined;
    }

    public onExcelExport(e: DataGridExcelExportEvent): void {
        const rows = [...e.rows];
        rows.forEach((row) => {
            const category = this.getCategoryById(row[0] as number);
            row[0] = category ? category.name : 'Unknown Category';
            row[2] = row[2] || (category ? category.color : 'Unknown Color');
        });
        e.updatedRows.next(rows);
    }

    public delete(): void {
        if (this.selectedRow instanceof Cable) {
            this.cableEditService.remove(this.selectedRow);
            this.cableModalRef.close();
        } else {
            this.cableCategoryEditService.remove(this.selectedRow);
            this.cableCategoryModalRef.close();
        }
    }

    public getAllCablesForExcelExport = (): Observable<DataGridResult> => {
        return this.cableEditService.getAllEntries();
    };
}
