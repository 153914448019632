import { Planning } from '@obo-main/services/plannings/plannings.models';

export class Project {
    public id: number;
    public name: string;
    public abbreviation: string;
    public creationDate: Date;
    public customerName: string;
    public customerNumber: string;
    public modificationDate: string;
    public number: string;
    public orderDate: string;
    public isReadOnly: boolean;
    public usageDate: string;
    public buildingCount: number;
    public plannedModules: number[];
}

export class Address {
    public city: string;
    public postalCode: string;
    public street: string;
    public houseNumber: string;
    public regionId: number;
    public latitude: number;
    public longitude: number;
}

export class Building extends Address {
    public id: number;
    public name: string;
    public plannings: ExtendedPlanning[];
}

export enum ModelUpdateAction {
    Create = 'Create',
    Update = 'Update',
    Delete = 'Delete'
}

export class ExtendedPlanning extends Planning {
    public planningState: string;
    public planningTileUrl: string;
}
