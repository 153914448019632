import { Component, Input, OnInit } from '@angular/core';
import { ProductRegionModel } from '@obo-admin/admin.models';
import { DataGridResult, DataGridState } from '@obo-admin/dataGrid/models/dataGrid.models';

@Component({
    selector: 'adm-product-values-detail',
    templateUrl: './productValuesDetail.component.html'
})
export class ProductValuesDetailComponent implements OnInit {
    @Input()
    public dataItems: ProductRegionModel[];
    data: DataGridResult;
    public dataGridState: DataGridState = {
        filterState: [],
        sortingState: [],
        paginationState: {
            top: 10,
            skip: 0
        }
    };

    ngOnInit(): void {
        this.data = { items: this.dataItems, count: this.dataItems.length + 1 };
    }
}
