import { Component, Input } from '@angular/core';

@Component({
    selector: 'shd-module-icon',
    templateUrl: './moduleIcon.component.html',
    styleUrls: ['./moduleIcon.component.scss']
})
export class ModuleIconComponent {
    @Input()
    public moduleName: string;
    @Input()
    public color: 'white' | 'gray' | 'primary-color' | 'secondary-color' | 'obo-blue-dark' | 'black';

    public moduleIconMap: { [moduleName: string]: [string, string] } = {
        // custom kit
        grounding: ['fak', 'grounding'],
        underfloor: ['fak', 'underfloor'],
        filling: ['fak', 'cablefilling'],
        overvoltage: ['fak', 'overvoltage'],
        // font awesome light
        incendiary: ['fal', 'fire'],
        egress: ['fal', 'person-running'],
        photovoltaic: ['fal', 'solar-panel']
    };
}
