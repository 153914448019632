import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { ModalService } from '@obo-main/services/modal/modal.service';
import { ModalRef } from '@obo-main/services/modal/modalRef.model';

@Component({
    selector: 'adm-portal-country-modal',
    templateUrl: './portalCountryModal.component.html',
    styleUrls: ['./portalCountryModal.component.scss'],
    exportAs: 'portalCountryModal'
})
export class PortalCountryModalComponent {
    @Output()
    public confirmed = new EventEmitter<void>();
    @Output()
    public dismissed = new EventEmitter<void>();

    @Input()
    public fg: UntypedFormGroup;

    public get formFeatures() {
        return <UntypedFormArray>this.fg!.controls.features;
    }

    private modalRef: ModalRef;

    @ViewChild('modal') modal: TemplateRef<any>;

    constructor(private modalService: ModalService, private viewContainerRef: ViewContainerRef) {}

    public show(): void {
        this.modalRef = this.modalService.open(this.modal, { viewContainerRef: this.viewContainerRef });
    }

    public close(): void {
        this.dismissed.emit();
        this.modalRef.dismiss();
    }

    public confirm(): void {
        this.confirmed.emit();
        this.modalRef.close();
    }
}
