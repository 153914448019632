import { FromApiData, StaticImplements } from '@obo-common/shared/models/FromApiData.interface';

@StaticImplements<FromApiData<CableCategory>>()
export class CableCategory {
    id: number;
    name: string;
    hasImage: boolean;
    isDeleted: boolean;
    color: string;
    image: string; // base64 string
    wireType: CableCategoryWireType;

    static fromApiData(data: any): CableCategory {
        return Object.assign(new CableCategory(), data);
    }
}

export enum CableCategoryWireType {
    Electricity = 1,
    Data = 2,
    Telecommunication = 3
}

@StaticImplements<FromApiData<Cable>>()
export class Cable {
    id: number;
    name: string;
    color: string;
    categoryId: number;
    diameter: number = 0; // diameter in mm
    load: number = 0;
    type: string;
    isDeleted: boolean;
    isCustom: boolean;
    isPinned: boolean;

    static fromApiData(data: any): Cable {
        return Object.assign(new Cable(), data);
    }
}

export interface CableEntry {
    id: number;
    width: number;
    height: number;
    x: number;
    y: number;
    label: string;
    color: string;
}
