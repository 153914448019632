<div id="region-management">
    <adm-header [title]="'NAVIGATION_PORTALCONFIG' | translate">
        <ng-template admHeaderActionButtons>
            <button class="btn btn-fa" [ngbTooltip]="'ADMIN_EXPORT_EXCEL_BTN' | translate"
                    (click)="grid.exportExcel()">
                <fa-icon [icon]="['fal', 'file-excel']" size="2x"></fa-icon>
            </button>
        </ng-template>
    </adm-header>
    <adm-data-grid
        (selectionChange)="selectRow($event)" (excelExport)="onExcelExport($event)"
        [data]="dataGridService | async" #grid [selectable]="true" [dataGridState]="dataGridState">
        <adm-data-grid-column field="name" title="{{ 'LANGUAGES_NAME' | translate }}"></adm-data-grid-column>
        <adm-data-grid-column
            field="displayName"
            [filterable]="false"
            [editable]="false"
            [sortable]="false"
            title="{{ 'SHARED_REGION' | translate }}">
            <ng-template admDataGridCell let-dataItem>
                {{ dataItem.displayName | translate }}
            </ng-template>
        </adm-data-grid-column>
        <adm-data-grid-excel fileName="portal_modules.xlsx"></adm-data-grid-excel>
    </adm-data-grid>
</div>

<!-- modals -->
<adm-portal-country-modal
    #editPortalCountryModal
    [fg]="formGroup"
    (confirmed)="saveHandler()"
    (dismissed)="cancelHandler()"></adm-portal-country-modal>
