import { AfterViewInit, Component, Input } from '@angular/core';
import { ResetPasswordRequest } from '@obo-account/models/account.models';
import { Logger } from '@obo-main/utils/logger/logger.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmAccountService } from '@obo-account/confirmAccount/confirmAccount.service';
import { SpinnerService } from '@obo-main/services/common/spinner/spinner.service';
import { AlertService } from '@obo-main/services/common/alert/alert.service';
import { StateService } from '@uirouter/core';

@Component({
    selector: 'app-confirm-account',
    templateUrl: './confirmAccount.component.html'
})
export class ConfirmAccountComponent implements AfterViewInit {
    @Input()
    public token: string | undefined;
    @Input()
    public userId: string | undefined;
    public data: ResetPasswordRequest = new ResetPasswordRequest();
    constructor(
        private logger: Logger,
        private translateService: TranslateService,
        private accountConfirmService: ConfirmAccountService,
        private spinnerService: SpinnerService,
        private alertSerice: AlertService,
        private stateService: StateService
    ) {}

    public ngAfterViewInit(): void {
        setTimeout(() => {
            this.logger.debug(`ConfirmAccount data: token: ${this.token}, userId: ${this.userId}`);
            if (typeof this.userId !== 'string' || typeof this.token !== 'string') {
                this.alertSerice.danger(this.translateService.instant('LOGINCTRL_INVALID_TOKEN'));
                this.stateService.go('login');
            } else {
                this.data.token = this.token;
                this.data.id = this.userId;
                this.confirmUserAccount(this.data);
            }
        });
    }

    private confirmUserAccount(data: ResetPasswordRequest): void {
        this.accountConfirmService.activateUserAccount(data.token, data.id).subscribe(
            () => {
                this.alertSerice.success(this.translateService.instant('LOGINCTRL_ACCOUNTCONFIRMSUCCESS'));
                this.spinnerService.stopSpinner();
                this.stateService.go('login');
            },
            () => {
                this.alertSerice.danger(this.translateService.instant('LOGINCTRL_ACCOUNTCONFIRMFAILURE'));
                this.spinnerService.stopSpinner();
                this.stateService.go('login');
            }
        );
    }
}
