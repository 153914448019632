import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { Region } from '@obo-main/services/region/region.models';
import { UntypedFormGroup } from '@angular/forms';
import { OboMultiSelectFilter, OboMultiSelectFilterOption } from '@obo-common/filter/models/multiSelect';
import { CartType } from '@obo-admin/admin.models';
import { DatePipe } from '@angular/common';
import { RegionService } from '@obo-main/services/region/region.service';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { DataGridService } from '@obo-admin/dataGrid/services/dataGrid.service';
import { Observable, Subject } from 'rxjs';
import {
    DataGridColumnCustomFilter,
    DataGridExcelExportEvent,
    DataGridFilterOperator,
    DataGridResult,
    DataGridState
} from '@obo-admin/dataGrid/models/dataGrid.models';
import { DataGridComponent } from '@obo-admin/dataGrid/dataGrid.component';
import { AlertService } from '@obo-main/services/common/alert/alert.service';
import { DataGridServiceFactory } from '@obo-admin/factories/dataGridService.factory';

@Component({
    selector: 'adm-router-analytics-management',
    templateUrl: './analyticsManagement.component.html'
})
export class AnalyticsManagementComponent implements OnDestroy, AfterViewInit {
    public regionList: Array<Region>;
    public regionMap: { [key: string]: string } = {};
    private onDestroy = new Subject();
    public formGroup: UntypedFormGroup;
    @ViewChild('grid')
    public grid: DataGridComponent;
    public dataGridState: DataGridState = {
        filterState: [],
        sortingState: [],
        paginationState: {
            top: 10,
            skip: 0
        }
    };
    public dataGridService: DataGridService;

    public filterFormGroup = new UntypedFormGroup({
        regions: new OboMultiSelectFilter(this.translateService.instant('ADMIN_ANALYTICS_ITEMRANGE'), false, [])
    });

    public range: { start: string; end: string };

    public analyticsItemCategory = CartType;
    public cartTypeFilterObject = this.createCartTypeFilterObject();
    public userFilterObject: DataGridColumnCustomFilter[] = [
        {
            value: true,
            label: this.translateService.instant('ADMIN_ANALYTICS_LOGGED_IN_USER')
        },
        {
            value: false,
            label: this.translateService.instant('ADMIN_ANALYTICS_GUEST')
        }
    ];

    constructor(
        @Inject('APIPREFIX') private apiPrefix: string,
        private datePipe: DatePipe,
        private changeDetectorRef: ChangeDetectorRef,
        private regionService: RegionService,
        private translateService: TranslateService,
        private dataGridServiceFactory: DataGridServiceFactory,
        private alertService: AlertService
    ) {
        this.range = {
            start: this.datePipe.transform(new Date(new Date().getFullYear(), new Date().getMonth(), 1), 'yyyy-MM-dd'),
            end: this.datePipe.transform(new Date(), 'yyyy-MM-dd')!
        };

        this.regionService.getRegions().subscribe((regions) => {
            this.regionList = regions;
            this.regionMap = this.regionList.reduce((obj, m) => {
                obj[m.name] = m.displayName;
                return obj;
            }, {} as any);

            this.regionList.forEach((r) => {
                (this.filterFormGroup.controls.regions as OboMultiSelectFilter<any>).push(
                    new OboMultiSelectFilterOption(this.translateService.instant(r.displayName), r.name, () => true, true)
                );
            });

            this.changeDetectorRef.detectChanges();
        });
        this.applyFilter();
        this.dataGridService = this.dataGridServiceFactory.getService(`${this.apiPrefix}Analytics/Administration/CartItems`);
    }

    public ngOnDestroy(): void {
        this.onDestroy.next(1);
        this.onDestroy.complete();
    }

    public applyFilter(reload?: boolean): void {
        const regionFormControl = this.filterFormGroup.controls.regions as OboMultiSelectFilter<any>;

        this.dataGridState.filterState = [
            {
                filters: regionFormControl.controls
                    .filter((o) => o.isActive)
                    .map((o) => ({
                        id: 'itemRange',
                        value: o.filterValue,
                        operator: DataGridFilterOperator.IsEqualTo
                    })),
                logic: 'or'
            }
        ];

        if (this.range.start && this.range.end) {
            this.checkDateRange();
            const dateFilter = [
                {
                    filters: [
                        {
                            id: 'creationDate',
                            value: `datetime'${this.range.start}'`,
                            operator: DataGridFilterOperator.GreaterThanOrEqualTo
                        },
                        {
                            id: 'creationDate',
                            value: `datetime'${this.range.end + 'T23:59:59.999'}'`,
                            operator: DataGridFilterOperator.LessThanOrEqualTo
                        }
                    ],
                    logic: 'and'
                }
            ];

            this.dataGridState.filterState.push(...dateFilter);

            if (reload) {
                this.dataGridService.read(this.dataGridState);
            }
        }
    }

    /**
     * transformes the excel export.
     * replaces translationkeys with actual values
     * This Fuction is used by Kendos ExcelExport Directive. Usually there is NO need to use this function on your own!
     * @param e
     */
    public onExcelExport(e: DataGridExcelExportEvent): void {
        let rows = [...e.rows];
        rows.forEach((row) => {
            // planningId
            row[1]
                ? (row[1] = this.translateService.instant('ADMIN_ANALYTICS_LOGGED_IN_USER'))
                : (row[1] = this.translateService.instant('ADMIN_ANALYTICS_GUEST'));
            // cartType
            if (row[14]) {
                row[14] = this.analyticsItemCategory[row[14] as number];
            }
            // creationDate
            if (row[15]) {
                row[15] = new Date(row[15]).toISOString();
            }
        });

        e.updatedRows.next(rows);
    }

    public fetchData(): () => Observable<DataGridResult> {
        return () => this.dataGridService.getAllEntries();
    }

    private createCartTypeFilterObject(): DataGridColumnCustomFilter[] {
        let map: DataGridColumnCustomFilter[] = [];
        for (let cartType in this.analyticsItemCategory) {
            if (typeof CartType[cartType] === 'number') {
                map.push({ value: <any>CartType[cartType], label: cartType });
            }
        }

        return map;
    }

    private checkDateRange() {
        if (this.range.end! < this.range.start) {
            this.range.end = this.range.start;
        }
    }

    ngAfterViewInit(): void {
        this.alertService.info(
            this.translateService.instant('ADMIN_ANALYTICS_INFO'),
            60,
            this.translateService.instant('ADMIN_ANALYTICS_INFO_TITLE')
        );

        this.grid.dataGridStateChange
            .pipe(
                debounceTime(200),
                distinctUntilChanged(),
                tap((state) => (this.dataGridState = state))
            )
            .subscribe((state) => this.dataGridService.read(state));

        this.filterFormGroup.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe(() => {
            this.applyFilter(true);
        });
    }
}
