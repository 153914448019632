import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SEOService } from '@obo-main/services/seo/seo.service';
import { StateService } from '@uirouter/core';

@Component({
    selector: 'app-display-content',
    templateUrl: './displayContent.component.html',
    styleUrls: ['./displayContent.component.scss']
})
export class DisplayContentComponent implements OnInit {
    @Input()
    showGoBackButton: boolean = false;
    public key: string;
    public title: string;
    constructor(stateService: StateService, private seo: SEOService, private translateService: TranslateService) {
        this.key = stateService.current.data.translationKey;
        this.title = stateService.current.data.metaTitleKey;
    }

    public ngOnInit(): void {
        this.seo.updateTitle(this.translateService.instant(this.title));
    }
}
