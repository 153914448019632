import { Component, ContentChild, Input } from '@angular/core';
import { SidebarTitleDirective } from '@obo-common/shared/components/sidebar/sidebarTitle.directive';
import { SidebarContentDirective } from '@obo-common/shared/components/sidebar/sidebarContent.directive';

@Component({
    selector: 'shd-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
    @Input() opened: boolean = false;
    @Input() showBackdrop: boolean = true;
    @Input() position: 'left' | 'right' = 'right';

    @ContentChild(SidebarTitleDirective)
    titleTpl: SidebarTitleDirective;

    @ContentChild(SidebarContentDirective)
    contentTpl: SidebarContentDirective;

    public toggle(): void {
        this.opened = !this.opened;
    }
}
