import { Directive, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Directive({ selector: 'ng-template[shdSingleSelectOption]' })
export class SingleSelectOptionDirective {
    @Input()
    public value: any;
    @Input()
    public active: boolean = true;
    @Input()
    public disabled: boolean = false;
    @Input()
    public groupName: string;
    @Input()
    public attDataTrack: string;
    @Output()
    public selected: EventEmitter<any> = new EventEmitter<any>();
    constructor(public templateRef: TemplateRef<any>) {}
}
