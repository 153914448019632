import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { User } from '@obo-account/models/account.models';
import { RegisterService } from '@obo-account/register/register.service';
import { AlertService } from '@obo-main/services/common/alert/alert.service';
import { SpinnerService } from '@obo-main/services/common/spinner/spinner.service';
import { Region } from '@obo-main/services/region/region.models';
import { SEOService } from '@obo-main/services/seo/seo.service';
import { PortalRegion } from '@obo-portal/models/portal.models';
import { StateService } from '@uirouter/core';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
    @Input()
    public passwordPattern: string;
    @Input()
    public regionList: Array<Region>;
    @Input()
    public selectedPortalRegion: PortalRegion;
    @ViewChild('innerHTML')
    public innerHTML: ElementRef;
    @ViewChild(NgForm)
    public form: NgForm;
    reCaptchaValid: boolean = false;
    constructor(
        private seo: SEOService,
        private translateService: TranslateService,
        private registerService: RegisterService,
        private spinnerService: SpinnerService,
        private alertService: AlertService,
        private stateService: StateService
    ) {}

    public ngOnInit(): void {
        this.seo.updateTitle(this.translateService.instant('REGISTER_META_TITLE'));
    }

    public get tosAcceptText(): string {
        let text = this.translateService.instant('REGISTERFORM_TOSACCEPTED');
        let privacyReplacement = this.translateService.instant('REGISTERFORM_PRIVACY');
        let tosReplacement = this.translateService.instant('REGISTERFORM_TOS');
        let tosUrl = `<a target="_blank" href="${this.stateService.href('termsOfService', {})}">${tosReplacement}</a>`;
        let privacyUrl = `<a target="_blank" href="${this.stateService.href('privacy', {})}">${privacyReplacement}</a>`;
        return text.replace('{{TOS_URL}}', tosUrl).replace('{{PRIVACY_URL}}', privacyUrl);
    }

    /**
     * uses the registerService to register a new account
     */
    public register(user: User) {
        user.regionId = this.selectedPortalRegion.id;
        this.spinnerService.startSpinner();
        this.registerService.register(user).subscribe({
            next: (res: any) => {
                this.spinnerService.stopSpinner();
                this.alertService.success(this.translateService.instant('LOGINCTRL_REISTERSUCCESS'));
                this.stateService.go('login');
            },
            error: (error: any) => {
                this.spinnerService.stopSpinner();
                if (error.status === 409) {
                    this.stateService.go('alreadyRegistered', {
                        email: user.email
                    });
                } else {
                    this.alertService.danger(this.translateService.instant('LOGINCTRL_REGISTERFAILURE'));
                }
            }
        });
    }
}
