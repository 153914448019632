import { Inject, Injectable, Injector, TemplateRef, Type } from '@angular/core';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { OVERLAY_S_CONFIG } from '@obo-main/injectionTokens/overlay.tokens';
import { LocationStrategy } from '@angular/common';
import { ModalRef } from '@obo-main/services/modal/modalRef.model';
import { ModalOptions } from '@obo-main/services/modal/modalOptions.model';
import { ActiveModal } from '@obo-main/services/modal/activeModal.model';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';

@Injectable()
export class ModalService {
    private _modalRefs: ModalRef[] = [];

    constructor(
        @Inject(OVERLAY_S_CONFIG)
        private defaultConfig: OverlayConfig,
        private overlay: Overlay,
        private locationStrategy: LocationStrategy
    ) {}

    public open(content: Type<any> | TemplateRef<any> | TemplatePortal, options: ModalOptions = {}): ModalRef {
        const activeModal = new ActiveModal();
        const modalRef = this._getOverlayPortalRef(content, activeModal, options);

        this._registerModalRef(modalRef);

        activeModal.close = (result: any) => {
            modalRef.close(result);
        };
        activeModal.dismiss = (reason: any) => {
            modalRef.dismiss(reason);
        };

        this.locationStrategy.onPopState(() => {
            this.dismissAll();
        });

        return modalRef;
    }

    public activeModals(): ModalRef[] {
        return this._modalRefs;
    }

    public dismissAll(): void {
        this._modalRefs.forEach((ref) => ref.dismiss());
    }

    private _registerModalRef(modalRef: ModalRef) {
        const unregisterModalRef = () => {
            const index = this._modalRefs.indexOf(modalRef);
            if (index > -1) {
                this._modalRefs.splice(index, 1);
            }
        };
        this._modalRefs.push(modalRef);
        modalRef.result.then(unregisterModalRef, unregisterModalRef);
    }

    private _getOverlayPortalRef(
        content: Type<any> | TemplateRef<any> | TemplatePortal,
        activeModal: ActiveModal,
        options: ModalOptions
    ): ModalRef {
        if (content instanceof TemplateRef) {
            return this._createFromTemplateRef(content, activeModal, options);
        } else if (content instanceof TemplatePortal) {
            return this._createFromTemplatePortal(content, activeModal, options);
        } else {
            return this._createFromComponent(content, activeModal, options);
        }
    }

    private _createFromTemplatePortal(templatePortal: TemplatePortal, activeModal: ActiveModal, options: ModalOptions): ModalRef {
        const context = {
            $implicit: activeModal
        };

        templatePortal.context = { ...templatePortal.context, context };
        const overlayRef = this.overlay.create(options.config ?? this.defaultConfig);
        overlayRef.attach(templatePortal);
        return new ModalRef(overlayRef, options);
    }

    private _createFromTemplateRef(templateRef: TemplateRef<any>, activeModal: ActiveModal, options: ModalOptions): ModalRef {
        const context = {
            $implicit: activeModal
        };

        const templatePortal = new TemplatePortal(templateRef, options.viewContainerRef, context);
        const overlayRef = this.overlay.create(options.config ?? this.defaultConfig);
        overlayRef.attach(templatePortal);
        return new ModalRef(overlayRef, options);
    }

    private _createFromComponent(componentType: Type<any>, activeModal: ActiveModal, options: ModalOptions): ModalRef {
        const contentInjector = options.injector;
        const elementInjector = Injector.create({
            providers: [{ provide: ActiveModal, useValue: activeModal }],
            parent: contentInjector
        });

        const componentPortal = new ComponentPortal(componentType, null, elementInjector);

        const overlayRef = this.overlay.create(options.config ?? this.defaultConfig);
        overlayRef.attach(componentPortal);
        return new ModalRef(overlayRef, options);
    }
}
