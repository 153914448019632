<section id="static-displayContent" class="max-w-100">
    <div class="static-content max-w-100">
        <div class="privacy-text" [innerHTML]="key | translate"></div>
        @if (showGoBackButton) {
        <button shdGoBack class="btn btn-step-back">
            <fa-icon [icon]="['fal', 'chevron-left']" size="2x"></fa-icon>
            {{ 'BTN_GOBACK' | translate }}
        </button>
        }
    </div>
</section>
