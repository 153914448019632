import { Component, Inject, OnInit } from '@angular/core';
import { Language } from '@obo-main/services/language/language.models';
import { PortalRegion } from '@obo-portal/models/portal.models';
import { ActiveModal } from '@obo-main/services/modal/activeModal.model';

@Component({
    selector: 'app-region-language-selector-modal',
    templateUrl: './regionLanguageSelectorModal.component.html',
    styleUrls: ['./regionLanguageSelectorModal.component.scss'],
    exportAs: 'regionLanguageSelectorModal'
})
export class RegionLanguageSelectorModalComponent implements OnInit {
    public showRegionSelection: boolean = true;
    public showAlert: boolean;

    constructor(
        @Inject('portalRegions')
        public portalRegions: PortalRegion[],
        @Inject('selectedRegion')
        public selectedRegion: PortalRegion | undefined,
        @Inject('saveRegionFn')
        public saveRegionFn: (region: PortalRegion) => void,
        @Inject('languages')
        public languages: Language[],
        @Inject('selectedLanguage')
        public selectedLanguage: Language | undefined,
        @Inject('saveLanguageFn')
        public saveLanguageFn: (language: Language) => void,
        @Inject('isDismissible')
        public isDismissible: boolean,
        @Inject(ActiveModal)
        public activeModal: ActiveModal
    ) {}

    public submit(): void {
        this.saveLanguageFn(this.selectedLanguage);
        this.saveRegionFn(this.selectedRegion);
        this.activeModal.close();
    }

    ngOnInit(): void {
        this.showAlert = !this.selectedRegion || !this.selectedLanguage;
    }
}
