import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { ModuleActionBarStep, ModuleActionBarStepGroup } from '@obo-main/components/moduleActionBar/moduleActionBarStep.model';
import { ModuleActionBarService } from '@obo-main/services/moduleActionBar/moduleActionBar.service';
import { AuthService } from '@obo-main/services/auth/auth.service';
import { Constants } from '../../../constants';
import { Utils } from '@obo-main/utils/utils.service';
import { TourEvent, TourService } from '@obo-main/services/tour/tour.service';

@Component({
    selector: 'app-module-action-bar',
    templateUrl: './moduleActionBar.component.html',
    styleUrls: ['./moduleActionBar.component.scss']
})
export class ModuleActionBarComponent implements OnInit {
    public currentStep: ModuleActionBarStep;
    public stepGroups: Array<ModuleActionBarStepGroup>;
    private visitedSteps: Array<string>;
    isTourActive: boolean = false;

    constructor(
        private stateService: StateService,
        private moduleActionBarService: ModuleActionBarService,
        private authService: AuthService,
        private utils: Utils,
        private tourService: TourService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.moduleActionBarService.steps.subscribe((steps) => {
            this.visitedSteps = new Array<string>();
            this.initStepGroups(steps);
        });

        this.moduleActionBarService.currentStep.subscribe((currentStep) => {
            this.currentStep = currentStep;
            if (currentStep && !this.visitedSteps.includes(currentStep.name)) {
                this.visitedSteps.push(currentStep.name);
            }
        });

        this.tourService.tourChange.subscribe((evt: TourEvent) => {
            this.isTourActive = !!evt;
            this.cdr.detectChanges();
        });
    }

    public goTo(stepGroup: ModuleActionBarStepGroup) {
        if (this.isCompleted(stepGroup)) {
            this.stateService.go(stepGroup.steps[0].name);
        }
    }

    public showStepGroup(stepGroup: ModuleActionBarStepGroup): boolean {
        const optional = this.isOptional(stepGroup);
        return !optional || (optional && (this.hasCurrentStep(stepGroup) || this.isCompleted(stepGroup)));
    }

    public goBack(): void {
        const previousStep = this.visitedSteps[this.visitedSteps.indexOf(this.currentStep.name) - 1];
        if (previousStep) {
            this.stateService.go(previousStep);
        } else {
            this.cancel();
        }
    }

    public isActive(stepGroup: ModuleActionBarStepGroup): boolean {
        return this.hasCurrentStep(stepGroup) || this.isCompleted(stepGroup);
    }

    public cancel(): void {
        const projectId = this.moduleActionBarService.projectId;
        if (this.isGuest) {
            this.stateService.go('portal');
        } else if (!projectId) {
            this.stateService.go('dashboard');
        } else {
            this.stateService.go('project', { projectId: projectId });
        }
    }

    public isCompleted(stepGroup: ModuleActionBarStepGroup): boolean {
        return (
            this.visitedSteps.includes(stepGroup.steps[0].name) &&
            this.stepGroups.indexOf(stepGroup) < this.stepGroups.indexOf(this.currentGroup)
        );
    }

    private initStepGroups(steps: Array<ModuleActionBarStep>): void {
        this.stepGroups = new Array<ModuleActionBarStepGroup>();
        if (steps) {
            this.utils.groupBy('icon', steps).map((group) => this.stepGroups.push(new ModuleActionBarStepGroup(group.values)));
        }
    }

    private isOptional(stepGroup: ModuleActionBarStepGroup): boolean {
        return stepGroup.steps.some((step) => step.optional);
    }

    private get currentGroup(): ModuleActionBarStepGroup {
        return this.stepGroups.find((group) => group.steps[0].icon === this.currentStep.icon);
    }

    private hasCurrentStep(stepGroup: ModuleActionBarStepGroup): boolean {
        return stepGroup.steps.map((step) => step.name).includes(this.currentStep.name);
    }

    private get isGuest(): boolean {
        return this.authService.isInRole([Constants.Role.Guest]);
    }
}
