import { Component, OnInit, ViewChild } from '@angular/core';
import { InAppNavigationService } from '@obo-main/services/inAppNavigation/inAppNavigation.service';
import { ConfirmModalComponent } from '@obo-common/shared/components/confirmModal/confirmModal.component';
import { TourEvent, TourService } from '@obo-main/services/tour/tour.service';
import { AppSettings } from '@obo-main/utils/appSettings.service';
import { LocationStrategy } from '@angular/common';
import { GuidedTourService } from '@obo-libraries/ngx-guided-tour/guided-tour.service';

@Component({
    selector: 'app-tour',
    templateUrl: './tour.component.html',
    styleUrls: ['./tour.component.scss']
})
export class TourComponent implements OnInit {
    public showTourToggle: boolean = false;
    inAppNavActive: boolean = false;

    @ViewChild('confirmModalRef')
    confirmModalRef: ConfirmModalComponent;

    constructor(
        private tourService: TourService,
        private inAppNavigationService: InAppNavigationService,
        private appSettings: AppSettings,
        private locationStrategy: LocationStrategy,
        private guidedTourService: GuidedTourService
    ) {}

    public ngOnInit(): void {
        if (this.appSettings.getItem('settings.enable_tours')) {
            this.tourService.toggleService(true);
            this.tourService.tourChange.subscribe((event: TourEvent) => {
                if (!event || (event.enabled && event.tour)) {
                    this.inAppNavigationService.isActive.subscribe((active) => (this.inAppNavActive = active));
                    this.showTourToggle = false;
                } else if (event.enabled && !event.tour) {
                    this.showTourToggle = true;
                }
            });
        }

        this.locationStrategy.onPopState(() => {
            setTimeout(() => {
                this.guidedTourService.resetTour();
            });
        });
    }

    public toggleRestartModal(): void {
        this.confirmModalRef.show();
    }
    public restartTour(): void {
        this.tourService.restartTour();
    }
}
