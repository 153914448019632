<div class="quick-start-card card-grow" (click)="forwardIfAllowed(module)" [attr.data-track]="'enterConfigurator_' + module.name">
    <div
        class="quick-start-body card-background-image"
        [ngClass]="module.name"
        [ngStyle]="{ 'background-image': linearGradient + 'url(/assets/img/dashboard/' + module.name + '.jpg)' }"></div>
    <div class="quick-start-footer">
        <div class="module-icon">
            <shd-module-icon [moduleName]="module.name" [color]="'black'"></shd-module-icon>
        </div>
        <h4 class="quick-start-footer-text">
            {{ 'BUILDING_MODULES_' + module.name | uppercase | translate }}
        </h4>
    </div>
</div>
