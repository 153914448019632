<form #addressForm="ngForm">
    <div class="mb-3">
        <label class="form-label" for="address">{{ 'SHARED_ADDRESS' | translate }}</label>
        <input
            class="form-control form-control-border"
            [ngModel]="address | addressLine"
            (ngModelChange)="onAddressChange($event)"
            name="address"
            id="address"
            type="text"
            [disabled]="disabled"
            [required]="required"
            (placeResult)="patchPlaceResult($event)"
            [mapAutocomplete]="{ componentRestrictions: { country: (address?.regionId | region | async)?.name } }"
            shdSelectOnFocus
            shdValidateInput />
    </div>
</form>
@if(missingAddressData && !disabled) {
<shd-infobox
    type="warning"
    [isDismissable]="false"
    [headerText]="'SHARED_ADRESS_INCOMPLETE_WARNING_TITLE' | translate"
    [bodyText]="('SHARED_ADRESS_INCOMPLETE_WARNING' | translate : this.missingAddressData).slice(0, -2)"></shd-infobox>
}
