<button class="btn btn-transparent py-0 px-1 info-button" tabindex="-1" type="button" [style.color]="color" (click)="openModal()">
    <fa-icon [icon]="['fal', 'info-circle']"></fa-icon>
</button>

<ng-template #infoModal let-modal>
    <div class="modal">
        <div class="modal-header py-1 px-1">
            <span class="d-flex header-icon">
                <fa-icon class="mx-2" [icon]="['fal', 'info-circle']"></fa-icon>
                {{ title !== undefined ? title : ('SHARED_INFO_TITLE' | translate) }}
            </span>
            <button type="button" class="btn-close ms-auto m-0 py-1 px-2" tabindex="-1" (click)="modal.dismiss()"></button>
        </div>
        <div class="modal-body">{{ text }}</div>
    </div>
</ng-template>
