import { Directive, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { Constants } from '../../../constants';

@Directive({
    selector: '[formControlName][shdValidateEmail],[formControl][shdValidateEmail],[ngModel][shdValidateEmail]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ValidateEmailDirective),
            multi: true
        }
    ]
})
export class ValidateEmailDirective implements Validator {
    public validate(control: AbstractControl): ValidationErrors | null {
        if (control && control.value) {
            const regexNaming = new RegExp(Constants.ValidationPattern.Email);
            if (!regexNaming.test(control.value)) {
                return {
                    email: true
                };
            }
        }
        return null;
    }
}
