import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthService } from '@obo-main/services/auth/auth.service';
import { AlertService } from '@obo-main/services/common/alert/alert.service';
import { SpinnerService } from '@obo-main/services/common/spinner/spinner.service';
import { DebugService } from '@obo-main/services/debugging/debug.service';
import { ElbridgeService } from '@obo-main/services/elbridge/elbridge.service';
import { ImageService } from '@obo-main/services/image/image.service';
import { LanguageService } from '@obo-main/services/language/language.service';
import { ModuleService } from '@obo-main/services/modules/module.service';
import { MotdService } from '@obo-main/services/motd/motd.service';
import { PlanningService } from '@obo-main/services/plannings/planning.service';
import { RegionService } from '@obo-main/services/region/region.service';
import { LocaleService } from './locale/locale.service';
import { SEOService } from './seo/seo.service';
import { StateHistoryService } from './stateHistory/stateHistory.service';
import { TourService } from '@obo-main/services/tour/tour.service';
import { ModalService } from '@obo-main/services/modal/modal.service';
/**
 * This module contains services which cannot be assigned to a specifig component eg. AuthService, which may be used by many components or other services.
 * More specifig services, e.g. a service for calculating view specifig stuff shall go together with the associated component into the component folder!
 */
@NgModule({
    imports: [],
    providers: []
})
export class ServicesModule {
    public static forRoot(): ModuleWithProviders<ServicesModule> {
        return {
            ngModule: ServicesModule,
            providers: [
                AuthService,
                ModuleService,
                LanguageService,
                AlertService,
                SpinnerService,
                ImageService,
                ElbridgeService,
                DebugService,
                PlanningService,
                MotdService,
                RegionService,
                SEOService,
                StateHistoryService,
                LocaleService,
                TourService,
                ModalService
            ]
        };
    }
}
