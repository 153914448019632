import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FileSavingService } from '@obo-common/shared/services/fileSaving.service';
import { ExtendedPlanning } from '@obo-dashboard/models/projectManagement.models';
import { AlertService } from '@obo-main/services/common/alert/alert.service';
import { SpinnerService } from '@obo-main/services/common/spinner/spinner.service';
import { MinimalModule } from '@obo-main/services/modules/module.models';
import { PlanningService } from '@obo-main/services/plannings/planning.service';
import { Logger } from '@obo-main/utils/logger/logger.service';
import { StateService } from '@uirouter/core';
import { Constants } from 'app/constants';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-planning-card',
    templateUrl: './planning-card.component.html',
    styleUrls: ['./planning-card.component.scss']
})
export class PlanningCardComponent {
    @Input()
    public projectId: number;
    @Input()
    public buildingId: number;
    @Input()
    public planning: ExtendedPlanning;
    @Input()
    public isMobile: boolean = false;

    @Output()
    public configuratorNonMobile: EventEmitter<any> = new EventEmitter();
    @Output()
    public reloadPlannings: EventEmitter<void> = new EventEmitter();
    @Output()
    public openDetails: EventEmitter<ExtendedPlanning> = new EventEmitter<ExtendedPlanning>();

    public nonImportExportModules: number[] = Constants.NonImportExportModules;
    public linearGradient: string = 'linear-gradient(to bottom, rgba(0,0,0,0), rgba(0, 0, 0, 0.7)),';

    constructor(
        private planningService: PlanningService,
        private logger: Logger,
        private translateService: TranslateService,
        private alertService: AlertService,
        private spinnerService: SpinnerService,
        private fileSavingService: FileSavingService,
        private stateService: StateService
    ) {}

    public deletePlanning(planningId: number) {
        this.planningService.deletePlanning(this.projectId, this.buildingId, planningId).subscribe({
            next: (): void => {
                this.reloadPlannings.emit();
                this.logger.info(`Planning ${planningId} deleted successfully`);
                this.alertService.success(this.translateService.instant('MAIN_DELETING_PLANNING_SUCCESS'));
            },
            error: (err): void => {
                this.logger.error(`Error deleting planning ${planningId}`, err);
                this.alertService.danger(this.translateService.instant('MAIN_DELETING_PLANNING_FAILURE'));
            }
        });
    }

    public exportPlanning(module: string, planningId: number): void {
        this.spinnerService.startSpinner();
        this.planningService
            .export(module, this.projectId, this.buildingId, planningId)
            .pipe(finalize(() => this.spinnerService.stopSpinner()))
            .subscribe({
                next: (file: { blob: Blob; name: string }): void => {
                    this.fileSavingService.saveFile(file.blob, file.name);
                },
                error: (err) => this.alertService.danger(this.translateService.instant('BUILDING_PLANNING_EXPORT_ERROR'), err)
            });
    }

    public moduleAllowsImportExport(): boolean {
        return !this.nonImportExportModules.includes(this.planning.moduleId);
    }

    public forwardIfAllowed(module: MinimalModule): void {
        if (this.isMobile && Constants.DesktopOnlyModules.some((m) => m === module.id)) {
            this.configuratorNonMobile.emit();
        } else {
            this.stateService.go(module.name, {
                buildingId: this.buildingId,
                projectId: this.projectId,
                planningId: this.planning.id
            });
        }
    }
}
